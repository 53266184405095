
const colorDark = '#222'
const colorDark2 = '#666'
const colorGray = '#e3e3e3'
const colorWhite = '#fff'

const styles= {
  'invoice-dark': {
    color: colorDark,
  },

  'invoice-white': {
    color: colorWhite,
  },

  'invoice-bg-dark': {
    backgroundColor: colorDark2,
  },

  'invoice-bg-gray': {
    backgroundColor: colorGray,
  },

  'invoice-flex': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
  },

  'invoice-w-auto': {
    flex: 1,
    paddingRight: '8px',
  },

  'invoice-ml-30': {
    flex: 1,
  },

  'invoice-w-100': {
    width: '100%',
  },

  'invoice-w-50': {
    width: '50%',
  },

  'invoice-w-55': {
    width: '55%',
  },

  'invoice-w-45': {
    width: '45%',
  },

  'invoice-w-60': {
    width: '60%',
  },

  'invoice-w-40': {
    width: '40%',
  },

  'invoice-w-48': {
    width: '48%',
  },

  'invoice-w-17': {
    width: '17%',
  },

  'invoice-w-18': {
    width: '18%',
  },
  'invoice-p-10':{
    paddingTop: '10px',
  },

  'invoice-invoice-row': {
    borderBottom: `1px solid ${colorGray}`,
  },

  'invoice-mt-40': {
    marginTop: '40px',
  },

  'invoice-mt-30': {
    marginTop: '30px',
  },

  'invoice-mt-20': {
    marginTop: '20px',
  },

  'invoice-mt-10': {
    marginTop: '10px',
  },

  'invoice-mb-5': {
    marginBottom: '5px',
  },

  'invoice-p-4-8': {
    padding: '4px 8px',
  },

  'invoice-p-5': {
    padding: '5px',
  },
  'invoice-p-top':{
    paddingTop:'5px',
  },
  'invoice-p-left':{
    paddingLeft:'5px',
  },
  'invoice-b-bottom':{
    borderBottom: '1px solid #666'
  },
  

  'invoice-pb-10': {
    paddingBottom: '10px',
  },

  'invoice-right': {
    textAlign: 'right',
  },

  'invoice-bold': {
    fontWeight: 'bold',
  },

  'invoice-fs-20': {
    fontSize: '20px',
  },

  'invoice-fs-45': {
    fontSize: '45px',
  },
  
  'invoice-page':{
    fontFamily: 'Nunito',
    fontSize: '12px',
    color: '#555',
    padding: '20px 35px',
  },
 

  'invoice-span': {
    padding: '2px 12px 2px 0',
  },

  'invoice-logo': {
    display: 'block',
  }
}

export default styles
