import {
  Button,
  IconButton,
  LinearProgress,
  makeStyles,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import { Form, Formik, Field, ErrorMessage } from "formik";
import { useSnackbar } from "notistack";

import React, { useRef, useState } from "react";
import Select from "react-select";
import { array, object, string } from "yup";
import Endpoint from "../../endpoint/index";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import { addSelectedField } from "../../helpers/helper";
import SelectField from "../../components/shared/SelectField";
import InputField from "../../components/shared/InputField";
import FMUInputField from "../../components/shared/FormIkMUFormComponent/FMUInputField";
import FormError from "../../components/FormError";
import Loading from "../Loading";
import fileData from "./fileHelperData.json";
import globalStyles from "../../helpers/GlobalStyles";

import SearchIcon from "@mui/icons-material/Search";

import { DASHBOARD, SEARCH } from "../../types/routeConstants";
import { useNavigate } from "react-router-dom";
// import ArrowBackIosNewIcon from "@material-ui/icons/ArrowBackIosNewIcon";

const useStyle = makeStyles((theme) => ({
  caseContainer: {
    // width: "928px",
    // marginTop: "14vh",
    width: "98%",
    paddingLeft: "2%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  searchFieldsRow: {
    display: "flex",
    alignItems: "center",
  },
  input: {
    "& .MuiInputBase-input": {
      // textIndent: "10px",
      fontSize: "0.9em",
      textTransform: "capitalize",
      //   textAlign: "end",
    },
    // height: "40px",
    paddingLeft: "3px",
    borderBottom: "1px solid #BABABA",
  },
  postButton: {
    backgroundColor: theme.palette.primary.main,
    // fontFamily: "poppins",
    //fontWeight: "bold",
    fontSize: ".8em",
    color: "#ffffff",
    textTransform: "inherit",
    // borderRadius: "5px",
    boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
    textAlign: "center",
    marginRight: "2%",
    border: "1px solid #3C72D9",

    width: "40px",
    height: "30px",
    // border: "1px solid #3C72D9",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.8",
    },
    "&.Mui-disabled": {
      backgroundColor: "#e7e7e7",
      color: "white",
    },
  },
  error: {
    fontSize: "12px",
    color: "red",
    marginTop: "0.25rem",
    position: "absolute",
  },
}));

const customStyles = {
  textAlign: "end",
  container: (provided, state) => ({
    ...provided,
    height: "44px",
  }),
  valueContainer: (provided, state) => ({
    ...provided,

    padding: "2px 0px 2px 8px",
  }),
  control: (base, state) => ({
    ...base,
    maxHeight: "34px !important",

    borderRadius: null,
    // Overwrittes the different states of border
    borderColor: null,

    borderStyle: null,
    borderBottom: "1px solid #BABABA",
    // Removes weird border around container
    boxShadow: null,
  }),
};

const options = [
  {
    label: "Name",
    value: "name",
  },
  {
    label: "Address",
    value: "address",
  },
  {
    label: "Loan #",
    value: "loan_no",
  },
];

const initialValues = {
  searchBy: "",
  searchQuery: "",
};

/**

A React functional component for rendering the Search Component.
It takes in a SearchBy field and a SearchQuery field - it returns a list of files.

*/
function SearchComponent({ setTabValue }) {
  const classes = useStyle();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const formRef = useRef();
  const globalClasses = globalStyles();

  // Handle Submit Function for search results
  const handleSubmit = (v, setSubmitting) => {
    setTabValue(DASHBOARD.index);
    setTimeout(() => {
      if (fileData.length == 1) {
        // go directly to file.
        navigate(`${SEARCH.path}/${fileData[0].id}`);
        enqueueSnackbar("File fetched successfully!", {
          variant: "success",
        });
        window.location.reload();
      } else {
        // go to fileList
        navigate(SEARCH.path, { state: { fileList: fileData } });
        enqueueSnackbar("Files fetched successfully!", {
          variant: "success",
        });
      }

      // handleTabChange(0, SEARCH.index);
      setSubmitting(false);
    }, 1000);
  };
  const validateSchema = object().shape({
    searchBy: string().required("Field is required"),
    searchQuery: string().required("Search query is required"),
  });
  return (
    <>
      <div className={classes.caseContainer}>
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validationSchema={validateSchema}
          validateOnBlur={false}
          innerRef={formRef}
          // validateOnChange
          onSubmit={(e, { setSubmitting }) => {
            handleSubmit(e, setSubmitting);
          }}
          render={({
            isSubmitting,
            dirty,
            touched,
            values,
            errors,
            setFieldValue,
          }) => (
            <Form>
              <>
                <div className={classes.searchFieldsRow}>
                  <span
                    style={{
                      width: "130px",
                      marginRight: "20px",
                      fontSize: "0.9em",
                    }}
                  >
                    <Select
                      value={
                        options
                          ? options.find(
                              (option) => option["value"] === values["value"]
                            )
                          : ""
                      }
                      onChange={(e) => {
                        setFieldValue("searchBy", e["value"], false);
                      }}
                      options={options}
                      styles={customStyles}
                      getOptionLabel={(option) => option["label"]}
                      getOptionValue={(option) => option["value"]}
                      placeholder={"Search By"}
                      components={{
                        IndicatorSeparator: () => null,
                        // DropdownIndicator: () => null,
                      }}
                    />
                    <ErrorMessage
                      name={"searchBy"}
                      component={FormError}
                      className={classes.error}
                    />
                  </span>
                  <span
                    style={{
                      width: "200px",
                      marginRight: "20px",
                    }}
                  >
                    <Field
                      name={"searchQuery"}
                      type={"text"}
                      className={classes.input}
                      component={FMUInputField}
                      label={
                        values.searchBy == "name"
                          ? "First Last"
                          : values.searchBy == "address"
                          ? "Number and Street Name"
                          : values.searchBy == "loan_no"
                          ? "Loan Number"
                          : "Search Query"
                      }
                    />
                    <ErrorMessage
                      name={"searchQuery"}
                      component={FormError}
                      className={classes.error}
                    />
                  </span>
                  <Button
                    className={classes.postButton}
                    // style={{ marginTop: "40px" }}
                    type={"submit"}
                    disabled={isSubmitting}
                  >
                    <SearchIcon />
                    {isSubmitting && (
                      <LinearProgress
                        color="primary"
                        className={globalClasses.linearProgress}
                      />
                    )}
                  </Button>
                </div>
              </>
            </Form>
          )}
        />
      </div>
    </>
  );
}

export default SearchComponent;
