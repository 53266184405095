import React, { FC, useEffect, useState } from 'react'
import { PDFDownloadLink } from '@react-pdf/renderer'
import InvoicePage from './InvoicePage'



function DownloadPDF({ data,caseData,check }){
  const [show, setShow] = useState(false)

  useEffect(() => {
    setShow(false)

    const timeout = setTimeout(() => {
      setShow(true)
    }, 500)

    return () => clearTimeout(timeout)
  }, [data])

  return (
    <div className={'invoice-download-pdf ' + (!show ? 'invoice-loading' : '')} title="Save PDF">
      <p>Download Pdf</p>
      {show && (
        <PDFDownloadLink
          document={<InvoicePage pdfMode={true} data={data} caseData={caseData} check={check}/>}
          fileName={`${data.invoiceTitle ? data.invoiceTitle.toLowerCase() : 'invoice'}.pdf`}
          aria-label="Save PDF"
        ></PDFDownloadLink>
      )}
    </div>
  )
}

export default DownloadPDF
