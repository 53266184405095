import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import clsx from "clsx";
import { Form, Formik } from "formik";
import { object, string } from "yup";
import {
  Button,
  IconButton,
  InputBase,
  LinearProgress,
  Tooltip,
  Typography,
} from "@material-ui/core";
import Link from "@material-ui/core/Link";
import { Pagination } from "@material-ui/lab";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import PropTypes from "prop-types";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import globalStyles from "../../../helpers/GlobalStyles";
import Select from "react-select";
// import CustomMenu from "../components/CustomMenu";

import NoSearchResults from "../../../dialogs/NoSearchResults";
// import ChangeUsername from '../dialogs/ChangeUsername';
import Endpoint from "../../../endpoint";
import { alertError } from "../../../helpers";
import Loading from "../../Loading";

import UploadCSV from "./UploadCSV";
import DashboardFilterAddress from "../../../dialogs/DashboardFilterAddress";

import PredictionTable from "../../../components/PredictionTable";
import PredictionTableDashboard from "../../../components/PredictionTableDashboard";
import { w3cwebsocket as W3CWebSocket } from "websocket";
import Papa from "papaparse";
import CsvDownload from "react-json-to-csv";

import UndoIcon from "@mui/icons-material/Undo";
import { AuthContext } from "../../../auth/AuthWrapper";

import UploadBatchSelectTable from "../../../components/UploadBatchSelectTable";

const useStyles = makeStyles(
  (theme) => ({
    tabContentStyleUploadBatch: {
      // marginTop: 12,
      // marginLeft: 25,
      fontFamily: "Helvetica Neue",
      "&$selected": {
        backgroundColor: "#147CFF",
      },
    },
    indicatorUploadBatch: {
      paddingBottom: "2px",
      marginBottom: "12px",
      width: "90px !important",
      marginLeft: "35px",
      color: "#147CFF",
      fontWeight: 800,
    },
    content: {
      width: "100%",
      paddingTop: "calc(3vh + 50px)",
      paddingLeft: "2vh",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
    },
    uploadBatchcontentTableDashboard: {
      width: "100%",
      // paddingTop: "calc(3vh + 50px)",
      paddingLeft: "5vh",
      paddingRight: "2vh",
      marginRight: "50vh",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
    },
    updateBatchContent: {
      width: "100%",
      paddingTop: "calc(3vh + 50px)",
      paddingLeft: "1%",
      // marginRight: "10vh",
      fontFamily: "Helvetica Neue",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
      alignItems: "center",
      marginBottom: "10px",
    },
    actions: {
      alignItems: "center",
      marginBottom: "10px",
    },
    actionsUpdateBatch: {
      // marginTop: "200px",
      // fontFamily: "Helvetica Neue",
    },
    contentTitle: {
      color: "#147CFF",
      marginBottom: "10px",
      fontSize: "25px",
    },
    tableSearchAddress: {
      paddingRight: "1vh",
    },
    uploadBatchcontentTitle: {
      color: "#147CFF",
      marginBottom: "10px",
      marginRight: "25px",
      marginLeft: "27px",
      fontSize: "25px",
    },
    actionButton: {
      backgroundColor: theme.palette.primary.main,
      // fontFamily: "poppins",
      //fontWeight: "bold",
      fontSize: ".8em",
      color: "#ffffff",
      textTransform: "inherit",
      borderRadius: "50px",
      boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
      textAlign: "center",
      marginRight: "2%",
      border: "1px solid #3C72D9",
      // height: "15px",
      padding: "8px",
      width: "100px",
      // border: "1px solid #3C72D9",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: "0.8",
      },
      "&.Mui-disabled": {
        backgroundColor: "#e7e7e7",
        color: "white",
      },
    },
    downloadResultsButton: {
      width: "140px",
      height: "35px",
    },
    redoButton: {
      backgroundColor: theme.palette.primary.main,
      marginLeft: "20px",
      fontSize: ".8em",
      backgroundColor: "#f8f8f8",
      textTransform: "inherit",
      boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
      textAlign: "center",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: "0.8",
        color: "white",
      },
      "&.Mui-disabled": {
        backgroundColor: "#e7e7e7",
        color: "white",
      },
    },
    processOrderButton: {
      backgroundColor: "#28C600",
      color: "#ffffff",
      fontSize: ".8em",
      textTransform: "inherit",
      borderRadius: "50px",
      boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
      textAlign: "center",
      // marginRight: "10%",
      border: "1px solid #3C72D9",
      height: "30px",
      width: "140px",
      "&:hover": {
        backgroundColor: "#ffffff",
        border: "2px solid #28C600",
        color: "#28C600",
      },
      "&.Mui-disabled": {
        backgroundColor: "#e7e7e7",
        color: "white",
      },
    },
    cancelOrderButton: {
      backgroundColor: "#EB0000",
      color: "#ffffff",
      fontSize: ".8em",
      textTransform: "inherit",
      borderRadius: "50px",
      boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
      textAlign: "center",
      marginRight: "2%",
      border: "1px solid #3C72D9",
      height: "30px",
      width: "120px",
      "&:hover": {
        backgroundColor: "#ffffff",
        color: "#EB0000",
        border: "2px solid #EB0000",
      },
      "&.Mui-disabled": {
        backgroundColor: "#e7e7e7",
        color: "white",
      },
    },
    actionButtonAgain: {
      width: "200px",
      height: "35px",
      float: "right",
      padding: 0,
      color: "#FFFFF",
      marginLeft: "32px",
      backgroundColor: "#147CFF",
      borderRadius: "50px",
      "&:hover": {
        backgroundColor: "#147CFF",
      },
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
      },
    },

    uploadBatchText: {
      // textAlign: "center",
      // fontFamily: "Helvetica Neue",
      // color: "#147CFF",
      marginBottom: "30px",
    },
    rightSide: {
      width: "100%",
    },

    selectedSearchAddress: {
      color: "#147CFF",
      fontWeight: 800,
      fontSize: "12px !important",
    },
    selectedUpdateBatch: {
      color: "#147CFF",
      fontWeight: 800,
      fontSize: "12px !important",
    },
  }),
  { index: 1 }
);

const customStyles = {
  textAlign: "end",
  valueContainer: (provided, state) => ({
    ...provided,
    padding: "2px 0px 2px 8px",
  }),
  control: (base, state) => ({
    ...base,
    minHeight: "40px !important",
    borderRadius: null,
    // Overwrittes the different states of border
    borderColor: null,

    borderStyle: null,
    borderBottom: "1px solid #BABABA",
    // Removes weird border around container
    boxShadow: null,
  }),
};

/**

A React functional component for rendering the Upload Batch page

 // TODO Integrate Upload batch api which takes in CSV and order type
 // TODO Get data from Lambda which will be triggered on upload

Planned process
Upload CSV
a Lambda will return rows to show in the table
once the users pick which ones we need to process , the file numbers picked will be fed to the model
or subsequent functions
what would be sent to the function that kicks of the subsequent process is just the file_numbers 
and we would query the SQL database for the information needed to create the results

*/
function UploadBatch({ tabData }) {
  const classes = useStyles();
  const location = useLocation();
  const [tabValue, setTabValue] = React.useState(0);
  const [tabValueUploadBatch, setTabValueUploadBatch] = React.useState(0);
  // console.log(location.pathname.split("/"));
  // if (location.pathname.split("/")[2] == "client") {
  //   console.log(location.pathname);
  //   setTabValue(4);
  // }
  const globalClasses = globalStyles();

  const [showNoSearchResultsDialog, setShowNoSearchResultsDialog] =
    useState(false); // state to toggle dialog box visibility when empty list returned

  const [uploadCSVDialog, setUploadCSVDialog] = useState(false);
  const [cSVSrc, setCSVSrc] = React.useState(undefined);

  const [isAdmin, setAdmin] = useState(false);
  const [isClientAdmin, setClientAdmin] = useState(false);
  const [clientName, setClientName] = useState(null);
  // const [cSVData, setCSVData] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [statusListDashboard, setStatusListDashboard] = useState([]);
  const [filteredStatusListDashboard, setFilteredStatusListDashboard] =
    useState([]);
  const itemsPerPage = 10;
  const [page, setPage] = React.useState(1);
  const [connectionId, setConncectionId] = useState(false);
  const [noOfPages] = React.useState(
    Math.ceil(statusList?.length / itemsPerPage)
  );

  const { orderTypes } = useContext(AuthContext);
  // const [orderTypes, setOrderTypes] = useState(null);
  const [selectedOrderType, setSelectedOrderType] = useState(1);
  const [uploadBatchUrl, setUploadBatchUrl] = useState(false);

  const [fileType, setFileType] = React.useState(undefined);
  const [fileName, setFileName] = React.useState(undefined);
  const [uploadedCSVArray, setUploadedCSVArray] = useState(null);

  const [fileUploaded, setFileUploaded] = useState(false);
  const [fileUploading, setFileUploading] = useState(false);

  const [orderProcessed, setOrderProcessed] = useState(false);
  const [orderProcessing, setOrderProcessing] = useState(false);

  const handleTabChangeUploadBatch = (event, newValue) => {
    setTabValueUploadBatch(newValue);
  };

  // Use effect to connect to web socket.
  useEffect(() => {
    // if (localStorage.getItem('connectionId')) {
    //     const wsConnectionId = localStorage.getItem('connectionId')
    //     setConncectionId(wsConnectionId)
    //     console.log(wsConnectionId)
    //     client.onmessage = (message) => {
    //         var msg = JSON.parse(message.data);
    //         console.log(msg)

    //       };
    // }
    // else {
    const client = new W3CWebSocket(
      "wss://8u13kwxw2k.execute-api.us-west-1.amazonaws.com/testing_stage"
    );
    localStorage.setItem("client", client);
    // console.log(client)
    client.onopen = (evt) => {
      // console.log("WebSocket Client Connected");
      client.send(JSON.stringify({ action: "defaultRoute" }));
    };
    client.onmessage = (message) => {
      var msg = JSON.parse(message.data);
      // console.log(msg);
      if (msg.hasOwnProperty("connectionId")) {
        var wsConnectionId = msg["connectionId"];
        localStorage.setItem("connectionId", wsConnectionId);
        setConncectionId(wsConnectionId);
      }
      if (msg.url) {
        updateUploadBatchUrl(msg);
      }
      // else {
      //   updateUploadBatchUrl('No search results');
      //   setShowNoSearchResultsDialog(true);
      // }
      // here custom response
    };
    client.onerror = (evt) => {
      console.log("***onError() :" + evt);
    };

    client.onclose = (evt) => {
      console.log("websocket closed :" + evt.code + ":" + evt.reason);
      console.log(evt);
    };
    // }
  }, []);

  //  TODO File uploaded and URL is returned, check link and set list to show table
  useEffect(() => {
    // console.log(uploadBatchUrl);
    if (uploadBatchUrl !== false) {
      Endpoint.Users.checkLink(uploadBatchUrl)
        .then((res) => {
          console.log("CheckLink");
          console.log(res);
          // console.log(res.status)
          if (res.status === 200) {
            // setGetURL(uploadBatchUrl)
            var data = Papa.parse(res.data, { header: true });
            // console.log(typeof data.data)
            // console.log( data.data)
            // console.log(res.data)
            setStatusList(data.data);
            // console.log(getURL)
          }
          // console.log(typeof res.data)

          // setRefreshing(false)
        })
        .catch((err) => {
          alertError("URL is incorrect", err);
          // setRefreshing(false)
          // setLoading(false);
        });
    }
  }, [uploadBatchUrl !== false]);

  const floorFigure = (figure, decimals) => {
    if (!decimals) decimals = 2;
    const d = Math.pow(10, decimals);
    return (parseInt(figure * d) / d).toFixed(decimals);
  };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const updateUploadBatchUrl = (event) => {
    // console.log("update function: ", event.url)
    setUploadBatchUrl(event.url);
  };

  function dataURItoBlob(dataURI) {
    // console.log(dataURI)
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    var blob = new Blob([ab], { type: mimeString });
    // console.log(byteString)
    // console.log(mimeString)
    // console.log(blob);
    return blob;
  }

  const csvFileToArray = (csvString) => {
    var lines = csvString.split(/\r?\n/);
    var result = [];
    var headers = lines[0].split(",");
    headers.push("orderID");
    headers.push("selected");

    // TODO - fetch latest order type record
    var obj = {
      date: new Date(),
      orderID: selectedOrderType,
      street: "4353 Westland Blvd",
      city: "Washington",
      state: "DC",
      zipcode: "29824",
      selected: false,
    };
    result.push(obj);
    for (var i = 1; i < lines.length; i++) {
      var obj = {};
      var currentline = lines[i].split(",");
      currentline.push(selectedOrderType);
      currentline.push(false);
      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j];
      }
      result.push(obj);
    }
    //return result; //JavaScript object
    // result = JSON.stringify(result); //JSON
    // console.log(result);
    return result;
    // setUploadedCSVArray(result);
  };

  useEffect(() => {
    console.log(uploadedCSVArray);
  }, [uploadedCSVArray]);

  // When user chooses CSV to upload
  const handleChooseCSV = ({ target }) => {
    const files = target.files;
    setFileName(files[0].name);
    setFileType(files[0].name.split(".")[1]);

    if (FileReader && files && files.length) {
      const fileReader = new FileReader();

      // Begin to ready file for upload
      fileReader.onload = (e) => {
        // setCSVSrc(e.target.result);
        // console.log(e.target.result);
        uploadCSV(e.target.result);
        // openUploadCSVDialog();
      };
      fileReader.readAsDataURL(files[0]);
    }

    if (FileReader && files && files.length) {
      const fileReader = new FileReader();

      // Begin to ready file for upload
      fileReader.onload = (e) => {
        const csvString = e.target.result;

        // Create Array of objects with CSV file details and insert orderType
        const result = csvFileToArray(csvString);
        setUploadedCSVArray(result);
        // console.log(result);
      };
      fileReader.readAsText(files[0]);
    }
  };

  // upload CSV
  const uploadCSV = (fileToUpload) => {
    setFileUploading(true);
    // console.log("HERE");
    var data = new FormData();
    // console.log(fileToUpload);
    data.append("file", dataURItoBlob(fileToUpload));

    const payload = {
      data: data,
      fileType: fileType,
      connectionId: connectionId,
    };

    setTimeout(() => {
      setFileUploaded(true);
      setFileUploading(false);
    }, 1000);

    // Endpoint.Users.upload(payload)
    //   .then((res) => {
    //     // setSearchResultsFound('');

    //     // if (res.data.status === false) {
    //     //   setSearchResultsFound(false);
    //     // } else {
    //     //   setSearchResultsFound('');
    //     //   setData(res.data);
    //     // }
    //     setData(res.data);
    //     setLoading(false);
    //     setRefreshing(false);
    //     setFileUploaded(true)
    //   })
    //   .catch((err) => {
    //     alertError("something went wrong", err);
    //     setLoading(false);
    //   });
  };

  const handleGetTemplate = () => {
    Endpoint.Users.getTemplate()
      .then((res) => {
        console.log(res.data);
        window.open(res.data.URL, "_blank", "noopener,noreferrer");
      })
      .catch((err) => {
        console.log("Error!!!!!!!");
        console.log(err);
        alertError("Error!!!!!!!", err);
      });
  };
  const handleStatusListUpdate = (values, filteredValues) => {
    setStatusListDashboard(values);
    setFilteredStatusListDashboard(filteredValues);
  };

  // function to filter order list with selected is true or when nothing is selected
  const filterUploadedCSVArray = () => {
    const filteredUploadedCSVArray = uploadedCSVArray.filter(
      (item) => item.selected === true
    );
    if (filteredUploadedCSVArray.length > 0) {
      return filteredUploadedCSVArray;
    } else {
      return uploadedCSVArray;
    }
  };

  const handleProcessOrder = () => {
    var filteredUploadedCSVArray = filterUploadedCSVArray();
    console.log(filteredUploadedCSVArray);
    setOrderProcessing(true);
    setTimeout(() => {
      setOrderProcessed(true);
      setOrderProcessing(false);
    }, 1000);
  };
  return (
    <div className={`${globalClasses.root} ${classes.content}`}>
      <div className={`${globalClasses.flexColumn}`}>
        {/* <NoSearchResults
          handleCloseNoSearchResultsDialog={handleCloseNoSearchResultsDialog}
          showNoSearchResultsDialog={showNoSearchResultsDialog}
        />
        <UploadCSV
          handleClose={closeUploadCSVDialog}
          open={uploadCSVDialog}
          cSVSrc={cSVSrc}
          fileType={fileType}
          connectionId={connectionId}
          handleCSVUpdate={updateUploadBatchUrl}
          uploadBatchUrl={uploadBatchUrl}
        /> */}
      </div>

      <>
        {uploadBatchUrl !== false ? (
          <div
            className={`${classes.uploadBatchcontentTableDashboard} ${globalClasses.flexRow}`}
          >
            <div className={`${classes.rightSide} ${globalClasses.flexColumn}`}>
              <span className={`${classes.uploadBatchcontentTitle}`}>
                <div className={` ${globalClasses.flexRow}`}>
                  <Tabs
                    TabIndicatorProps={{
                      style: { backgroundColor: "#147CFF" },
                    }}
                    value={tabValueUploadBatch}
                    indicatorColor="primary"
                    onChange={handleTabChangeUploadBatch}
                    aria-label="simple tabs example"
                    className={classes.tabContentStyleUploadBatch}
                  >
                    <Tab
                      classes={{
                        indicator: classes.indicatorUploadBatch,
                      }}
                      style={{ fontSize: "20px" }}
                      // classes={{ selected: classes.selectedSearchAddress }}
                      label="Table"
                      {...a11yPropsUploadBatch(0)}
                    />
                    <Tab
                      style={{ fontSize: "20px" }}
                      classes={{
                        selected: classes.selectedUpdateBatch,
                      }}
                      label="Dashboard"
                      {...a11yPropsUploadBatch(1)}
                    />
                  </Tabs>
                </div>
              </span>

              <TabPanel value={tabValueUploadBatch} index={0}>
                <span className={` ${globalClasses.flexRowReverse}`}>
                  <div style={{ alignSelf: "end", marginTop: "3.5rem" }}>
                    <Tooltip title="Upload CSV Again">
                      <IconButton
                        component="span"
                        className={classes.actionButtonAgain}
                        onClick={() => setUploadBatchUrl(false)}
                      >
                        <Typography variant="body1" style={{ color: "#FFFF" }}>
                          Upload CSV Again
                        </Typography>
                      </IconButton>
                    </Tooltip>
                    <CsvDownload
                      style={{
                        padding: "5px",
                        borderRadius: "20px",
                        color: "white",
                        cursor: "pointer",
                        backgroundColor: "#147CFF",
                      }}
                      data={statusList}
                    />
                  </div>
                </span>
                <div style={{ marginTop: "1rem" }}>
                  <PredictionTable statusList={statusList} isAdmin={isAdmin} />
                </div>
              </TabPanel>

              <TabPanel value={tabValueUploadBatch} index={1}>
                <Box borderColor="#808080" {...dashboardFilterBoxProps}>
                  <DashboardFilterAddress
                    statusUpdate={handleStatusListUpdate}
                    clientName={clientName}
                  />
                </Box>
                <span className={` ${globalClasses.flexRowReverse}`}>
                  <div style={{ alignSelf: "end", marginTop: "3.5rem" }}>
                    <Tooltip title="Upload CSV Again">
                      <IconButton
                        component="span"
                        className={classes.actionButtonAgain}
                        onClick={() => setUploadBatchUrl(false)}
                      >
                        <Typography variant="body1" style={{ color: "#FFFF" }}>
                          Upload CSV Again
                        </Typography>
                      </IconButton>
                    </Tooltip>
                    <CsvDownload
                      style={{
                        padding: "5px",
                        borderRadius: "20px",
                        cursor: "pointer",
                        color: "white",
                        backgroundColor: "#147CFF",
                      }}
                      data={filteredStatusListDashboard}
                    />
                  </div>
                </span>
                <div style={{ marginTop: "1rem" }}>
                  <PredictionTableDashboard
                    statusList={statusListDashboard}
                    isAdmin={isAdmin}
                  />
                </div>
              </TabPanel>
            </div>
          </div>
        ) : (
          // <div className={`${classes.updateBatchContent} ${globalClasses.flexRow}`}>
          <div className={globalClasses.flexColumn}>
            <div
              className={`${classes.updateBatchContent} ${classes.actionsUpdateBatch} ${globalClasses.flexRow}`}
            >
              {!fileUploaded ? (
                <div className={globalClasses.flexColumn}>
                  <Box className={classes.uploadBatchText}>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginBottom: "30px",
                      }}
                    >
                      <Typography
                        variant="subtitle1"
                        style={{ fontWeight: 600 }}
                      >
                        Order Type
                      </Typography>
                      {orderTypes ? (
                        <span
                          style={{
                            marginLeft: "16px",
                          }}
                        >
                          <Select
                            // value={{ label: values.order_name }}
                            value={
                              orderTypes
                                ? orderTypes.find(
                                    (option) =>
                                      option?.orderID === selectedOrderType
                                  )
                                : ""
                            }
                            onChange={(option) =>
                              setSelectedOrderType(option.orderID)
                            }
                            getOptionLabel={(option) => option.ordertype}
                            getOptionValue={(option) => option.orderID}
                            options={orderTypes}
                            styles={customStyles}
                          />
                        </span>
                      ) : null}
                    </div>
                    <Typography variant="body1">
                      Upload File for batch processing. For more information
                      follow the{" "}
                      <a href="#" onClick={handleGetTemplate}>
                        Template
                      </a>
                    </Typography>
                  </Box>

                  <div className={globalClasses.flexRow}>
                    <input
                      accept="text/csv"
                      onChange={handleChooseCSV}
                      id="button-csv-upload"
                      style={{ display: "none" }}
                      type="file"
                    />
                    <label htmlFor="button-csv-upload">
                      <Tooltip title="Upload CSV">
                        <IconButton
                          component="span"
                          className={classes.actionButton}
                          disabled={fileUploading}
                        >
                          <Typography variant="body2">Upload Batch</Typography>
                        </IconButton>
                      </Tooltip>
                    </label>
                    {fileUploading ? (
                      <span style={{ marginLeft: "5%" }}>
                        <Loading />
                      </span>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className={globalClasses.flexColumn}>
                  {!orderProcessed ? (
                    <>
                      {" "}
                      <Box className={classes.uploadBatchText}>
                        <Typography variant="body1">
                          Batch File Uploaded!
                        </Typography>
                        <Typography variant="body2">
                          {fileName ? fileName : null}
                        </Typography>
                      </Box>
                      {uploadedCSVArray?.length > 0 && (
                        <UploadBatchSelectTable
                          uploadedCSVArray={uploadedCSVArray}
                          setUploadedCSVArray={setUploadedCSVArray}
                          orderTypes={orderTypes}
                        />
                      )}
                      <div className={globalClasses.flexRowReverse}>
                        <Tooltip title="Process Batch File">
                          <Button
                            className={classes.processOrderButton}
                            onClick={() => handleProcessOrder()}
                          >
                            {orderProcessing ? (
                              <>
                                {"Processing Order"}
                                <LinearProgress
                                  color="primary"
                                  className={globalClasses.linearProgress}
                                />
                              </>
                            ) : (
                              <>{"Process Order"}</>
                            )}
                          </Button>
                        </Tooltip>
                        <Tooltip title="Process Batch File">
                          <Button
                            className={classes.cancelOrderButton}
                            onClick={() => setFileUploaded(false)}
                          >
                            Cancel
                          </Button>
                        </Tooltip>
                      </div>
                    </>
                  ) : (
                    <>
                      {" "}
                      <Box className={classes.uploadBatchText}>
                        <Typography variant="body1">Order Complete!</Typography>
                      </Box>
                      <div
                        className={globalClasses.flexRow}
                        style={{ alignContent: "center" }}
                      >
                        <Tooltip title="Download Results">
                          <Button
                            className={`${classes.actionButton} ${classes.downloadResultsButton}`}
                          >
                            Download Results
                          </Button>
                        </Tooltip>
                        <Tooltip title="Redo Upload">
                          <Button
                            className={classes.redoButton}
                            onClick={() => {
                              setFileUploaded(false);
                              setOrderProcessed(false);
                            }}
                          >
                            <UndoIcon />
                          </Button>
                        </Tooltip>
                      </div>
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </>
    </div>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function TabPanelUploadBatch(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpaneluploadbatch"
      hidden={value !== index}
      id={`simple-tabpaneluploadbatch-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanelUploadBatch.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yPropsUploadBatch(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpaneluploadbatch-${index}`,
  };
}

const defaultProps = {
  bgcolor: "background.paper",
  color: "#147CFF",
  m: 1,
  border: 2,
  style: {
    width: "17rem",
    height: "27rem",
    paddingTop: "20px",
    borderRadius: "20px",
    display: "inline-table",
    paddingRight: "5px",
    paddingLeft: "5px",
  },
};
const dashboardFilterBoxProps = {
  bgcolor: "background.paper",
  color: "#147CFF",
  m: 2,
  border: 2,
  style: {
    width: "40rem",
    height: "14rem",
    paddingTop: "20px",
    borderRadius: "20px",
    display: "flex",
    margin: "auto",
  },
};
const currentUsernameProps = {
  m: 1,
  // border: 2,
  style: {
    width: "8.5rem",
    height: "0.2rem",
    alignContents: "center",
    display: "inline-table",
    margin: 0,
    marginLeft: "23%",
    paddingRight: "5px",
    paddingLeft: "5px",
  },
};

export default UploadBatch;
