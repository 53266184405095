import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Pagination } from "@material-ui/lab";
import globalStyles from "../helpers/GlobalStyles";
import "./SearchInvoiceTable.css";
const useStyles = makeStyles(
  (theme) => ({
    paginator: {
      justifyContent: "center",
      padding: "10px",
    },
  }),
  { index: 1 }
);

/**

A React functional component for Dashboard Invoice Table.
This table is rendered when user hits Search Invoices in the Dashboard tab

*/
function SearchInvoiceTable(props) {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const { invoices, setInvoices, isAdmin, columnNames } = props;
  const itemsPerPage = 10;
  const [page, setPage] = React.useState(1);
  // const [connectionId, setConncectionId]= useState(false);
  // const [noOfPages] = React.useState(
  //     Math.ceil(invoices?.length / itemsPerPage)
  // );
  var noOfPages = Math.ceil(invoices?.length / itemsPerPage);
  // console.log(invoices);
  // const floorFigure = (figure, decimals) => {
  //   if (!decimals) decimals = 2;
  //   const d = Math.pow(10, decimals);
  //   return (parseInt(figure * d) / d).toFixed(decimals);
  // };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleCheckboxChange = (id) => {
    setInvoices((prevData) =>
      prevData.map((item) =>
        item.id === id ? { ...item, selected: !item.selected } : item
      )
    );
  };

  const handleSelectAll = () => {
    setInvoices((prevData) =>
      prevData.map((item) => ({
        ...item,
        selected: invoices.every((item) => item.selected)
          ? !item.selected
          : true,
      }))
    );
  };

  const allSelected = invoices.every((item) => item.selected);

  return (
    <>
      <table className={globalClasses.clientTable}>
        <thead className={globalClasses.tableHead}>
          <tr className={globalClasses.clientthtr}>
            <th className={globalClasses.tableTh}>
              <input
                type="checkbox"
                checked={allSelected}
                onChange={handleSelectAll}
              />
            </th>

            {columnNames.map((columnName, index) => (
              <th key={index} className={globalClasses.tableTh}>
                {columnName.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {invoices
            ?.slice((page - 1) * itemsPerPage, page * itemsPerPage)
            .map((status, index) => (
              <tr key={index}>
                <td className={globalClasses.tableTd}>
                  <input
                    type="checkbox"
                    checked={status.selected}
                    onChange={() => handleCheckboxChange(status.id)}
                  />
                </td>

                {columnNames.map((columnName, index) => (
                  <td key={index} className={globalClasses.tableTd}>
                    {status[columnName.value]}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      <Pagination
        count={noOfPages}
        page={page}
        onChange={handleChange}
        defaultPage={1}
        color="primary"
        showFirstButton
        showLastButton
        classes={{ ul: classes.paginator }}
      />
    </>
  );
}
export default SearchInvoiceTable;
