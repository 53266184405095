import {
  Button,
  makeStyles,
  AccordionDetails,
  Accordion,
  AccordionSummary,
} from "@material-ui/core";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Select from "react-select";
import {
  useFormikContext,
  getIn,
  ErrorMessage,
  Field,
  FieldArray,
  Form,
  Formik,
} from "formik";
import { useSnackbar } from "notistack";
import InputMask from "react-input-mask";
import React, { useRef, useState, useEffect, useContext } from "react";

import { array, date, number, object, string, boolean } from "yup";
import * as Yup from "yup";
import Endpoint from "../../endpoint";
// import OpenDialogIcon from "../../icons/OpenDialog";
import { states } from "../../helpers/states";
import ClientField from "../../components/shared/InputField";
import FMUInputField from "../../components/shared/FormIkMUFormComponent/FMUInputField";
// import ContactsDialog from "./ContactsDialog";
import {
  ContactInformationJson,
  convertToAcronym,
  generateQuickGuid,
  getTaxIDMask,
} from "../../helpers/helper";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MaskField from "../../components/shared/MaskField";

import { daysOfTheWeek } from "../../helpers/daysOfTheWeek";
import { getDates } from "../../helpers/helper";
// import ArrowBackIosNewIcon from "@material-ui/icons/ArrowBackIosNewIcon";
import InvoiceField from "../../components/shared/InvoiceFields/InvoiceField";
import FormError from "../../components/FormError";

import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";

import fileData from "./fileHelperData.json";

import FileDetail from "./FileDetail";
import WorkOrderTable from "./WorkOrderTable";
import { AuthContext } from "../../auth/AuthWrapper";
import { useParams } from "react-router-dom";
import Loading from "../Loading";

import { DASHBOARD, INVOICE } from "../../types/routeConstants";
import { useNavigate } from "react-router-dom";
import NewOrderDialog from "./NewOrderDialog";

const useStyle = makeStyles((theme) => ({
  rowContainer: {
    display: "flex",
  },
  leftRow: {
    width: "50%",
    display: "flex",
  },
  rightRow: {
    width: "50%",
    display: "flex",
  },
  leftSide: {
    width: "35%",
  },
  rightSide: {
    width: "50%",
  },
  title: {
    color: "#147CFF",
    fontWeight: "600",
  },
  text: {
    marginRight: "10px",
  },
  summaryTitleStyle: {
    color: "#147CFF",
    fontWeight: "600",
    marginRight: "30px",
  },
  detailStyle: {
    display: "inherit !important",
  },
  postButton: {
    backgroundColor: "#f8f8f8",
    fontSize: ".8em",
    textTransform: "inherit",
    boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
    textAlign: "center",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.8",
      color: "white",
    },
    "&.Mui-disabled": {
      backgroundColor: "#e7e7e7",
      color: "black",
    },
  },
}));

/**

A React functional component for rendering the Contact Information Tab

*/
function ContactInformation({ workOrders }) {
  const classes = useStyle();
  const navigate = useNavigate();
  const [showNewOrderDialog, setShowNewOrderDialog] = useState(false); // state to toggle dialog box visibility for requesting a new Work Order
  const [expanded, setExpanded] = React.useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  function renderData(data) {
    return data ? data : "-";
  }

  const openNewOrderDialog = () => {
    setShowNewOrderDialog(true);
  };

  const handleCloseNewOrderDialog = () => {
    setShowNewOrderDialog(false);
  };

  return (
    <FieldArray
      name="work_orders"
      render={(arrayHelpers) => (
        <div>
          <NewOrderDialog
            showNewOrderDialog={showNewOrderDialog}
            handleCloseNewOrderDialog={handleCloseNewOrderDialog}
            arrayHelpers={arrayHelpers}
            selectedOrderType={3}
            workOrderJson={ContactInformationJson}
          />
          <Button
            className={classes.postButton}
            style={{ marginLeft: "20px" }}
            onClick={() => {
              openNewOrderDialog();
            }}
          >
            Place New Order
          </Button>
          {workOrders?.map((workOrder) => (
            <>
              {workOrder.order_type == 3 && (
                <div style={{ marginTop: "20px", width: "95%" }}>
                  <Accordion
                    expanded={expanded === workOrder.work_order}
                    onChange={handleChange(workOrder.work_order)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <p className={classes.summaryTitleStyle}>
                        {renderData(workOrder.request_date)}
                      </p>
                      <p className={classes.text}>{`Address: `}</p>
                      <p className={classes.text}>
                        {renderData(
                          `${workOrder.current_address?.street_address} ${workOrder.current_address?.street_address_2} ${workOrder.current_address?.city} ${workOrder.current_address?.state} ${workOrder.current_address?.zip}`
                        )}
                      </p>
                    </AccordionSummary>
                    <AccordionDetails classes={{ root: classes.detailStyle }}>
                      {/* Date ordered and completed */}
                      <div className={classes.rowContainer}>
                        <div className={classes.leftRow}>
                          <div className={classes.leftSide}>
                            <p className={classes.title}>Date Ordered</p>
                          </div>
                          <div className={classes.rightSide}>
                            <p className={classes.text}>
                              {renderData(workOrder.request_date)}
                            </p>
                          </div>
                        </div>

                        <div className={classes.rightRow}>
                          <div className={classes.leftSide}>
                            <p className={classes.title}>Date Completed</p>
                          </div>
                          <div className={classes.rightSide}>
                            <p className={classes.text}>
                              {renderData(workOrder.date_completed)}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Subject Name */}
                      <div className={classes.rowContainer}>
                        <div className={classes.leftRow}>
                          <div className={classes.leftSide}>
                            <p className={classes.title}>Borrower:</p>
                          </div>
                          <div className={classes.rightSide}>
                            <p className={classes.text}>
                              {renderData(
                                `${workOrder.client_provided_suffix} ${workOrder.client_provided_first_name} ${workOrder.client_provided_middle_name} ${workOrder.client_provided_last_name}`
                              )}
                            </p>
                          </div>
                        </div>

                        <div className={classes.rightRow}>
                          <div className={classes.leftSide}>
                            <p className={classes.title}></p>
                          </div>
                          <div className={classes.rightSide}>
                            <p className={classes.text}></p>
                          </div>
                        </div>
                      </div>

                      {/* Addresses */}
                      <div className={classes.rowContainer}>
                        <div className={classes.leftRow}>
                          <div className={classes.leftSide}>
                            <p className={classes.title}>Current Address:</p>
                          </div>
                          <div className={classes.rightSide}>
                            <p className={classes.text}>
                              {renderData(
                                `${workOrder.current_address?.street_address} ${workOrder.current_address?.street_address_2} ${workOrder.current_address?.city} ${workOrder.current_address?.state} ${workOrder.current_address?.zip}`
                              )}
                            </p>
                          </div>
                        </div>

                        <div className={classes.rightRow}>
                          <div className={classes.leftSide}>
                            <p className={classes.title}>Current Address 2:</p>
                          </div>
                          <div className={classes.rightSide}>
                            <p className={classes.text}>
                              {" "}
                              {renderData(
                                `${workOrder.current_address_2?.street_address} ${workOrder.current_address_2?.street_address_2} ${workOrder.current_address_2?.city} ${workOrder.current_address_2?.state} ${workOrder.current_address_2?.zip}`
                              )}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Phone */}
                      <div className={classes.rowContainer}>
                        <div className={classes.leftRow}>
                          <div className={classes.leftSide}>
                            <p className={classes.title}>Phone:</p>
                          </div>
                          <div className={classes.rightSide}>
                            <p className={classes.text}>
                              {workOrder.phone?.phone1}
                            </p>
                          </div>
                        </div>

                        <div className={classes.rightRow}>
                          <div className={classes.leftSide}>
                            <p className={classes.title}></p>
                          </div>
                          <div className={classes.rightSide}>
                            <p className={classes.text}>
                              {workOrder.phone?.phone2}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={classes.rowContainer}>
                        <div className={classes.leftRow}>
                          <div className={classes.leftSide}>
                            <p className={classes.title}></p>
                          </div>
                          <div className={classes.rightSide}>
                            <p className={classes.text}>
                              {workOrder.phone?.phone3}
                            </p>
                          </div>
                        </div>

                        <div className={classes.rightRow}>
                          <div className={classes.leftSide}>
                            <p className={classes.title}></p>
                          </div>
                          <div className={classes.rightSide}>
                            <p className={classes.text}>
                              {workOrder.phone?.phone4}
                            </p>
                          </div>
                        </div>
                      </div>

                      {/* Email */}
                      <div className={classes.rowContainer}>
                        <div className={classes.leftRow}>
                          <div className={classes.leftSide}>
                            <p className={classes.title}>Email:</p>
                          </div>
                          <div className={classes.rightSide}>
                            <p className={classes.text}>
                              {workOrder.email?.email1}
                            </p>
                          </div>
                        </div>

                        <div className={classes.rightRow}>
                          <div className={classes.leftSide}>
                            <p className={classes.title}></p>
                          </div>
                          <div className={classes.rightSide}>
                            <p className={classes.text}>
                              {workOrder.email?.email2}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className={classes.rowContainer}>
                        <div className={classes.leftRow}>
                          <div className={classes.leftSide}>
                            <p className={classes.title}></p>
                          </div>
                          <div className={classes.rightSide}>
                            <p className={classes.text}>
                              {workOrder.email?.email3}
                            </p>
                          </div>
                        </div>

                        <div className={classes.rightRow}>
                          <div className={classes.leftSide}>
                            <p className={classes.title}></p>
                          </div>
                          <div className={classes.rightSide}>
                            <p className={classes.text}>
                              {workOrder.email?.email4}
                            </p>
                          </div>
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              )}
            </>
          ))}
        </div>
      )}
    />
  );
}

export default ContactInformation;
