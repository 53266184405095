import React, { useContext, useEffect, useState } from "react";
import { Button, TextField, Tooltip, makeStyles } from "@material-ui/core";

// import Loader from "react-loader-spinner";
import Loading from "../Loading";
import "./ClientList.css";

// import CustomLoader from '../../components/CustomLoader'
import EditClient from "../../icons/EditClient";
import DeleteIcon from "@mui/icons-material/Delete";

import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import FormError from "../../components/FormError";
import ClientField from "../../components/shared/InputField";
import FeeField from "../../components/shared/FeeField";
import { getPanelId } from "@material-ui/lab";
// import { orderTypes } from "../../helpers/orderTypes";
import Endpoint from "../../endpoint/index";
import Select from "react-select";
import { AuthContext } from "../../auth/AuthWrapper";

const useStyle = makeStyles((theme) => ({
  header: {
    backgroundColor: "#e7e7e7",
  },
  clientTable: {
    width: "100%",
    textAlign: "left",
    // color: "#4e4e4e",
    borderCollapse: "separate",
    borderSpacing: "0 15px",
    fontSize: "0.9em",
    // maxWidth:"fit-content",
    // overflow: "visible",
    // display: "block",
    // overflowX: "auto",
    whiteSpace: "nowrap",
  },
  clientthtr: {
    backgroundColor: "#0000",
    borderRadius: "25px",
    paddingTop: "5px",
    height: "30px",
  },

  tableTd: {
    paddingLeft: "10px",
    whiteSpace: "nowrap",
  },
  tableTdName: {
    cursor: "pointer",
    color: "#197EFF",
    paddingLeft: "10px",
    whiteSpace: "nowrap",
  },
  tableTh: {
    textAlign: "left",
    paddingLeft: "10px",
    whiteSpace: "nowrap",
  },
  tableIcons: {
    overflow: "visible",
    cursor: "pointer",
    marginRight: "15px",
    width: "20px",
    "&:hover": {
      color: "red",
    },
  },
  postButton: {
    backgroundColor: theme.palette.primary.main,
    fontFamily: "poppins",
    //fontWeight: "bold",
    fontSize: "0.9em",
    color: "#ffffff",
    textTransform: "inherit",
    // borderRadius: "5px",
    boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
    textAlign: "center",
    marginBottom: "2%",
    // marginRight: "2%",
    border: "1px solid #3C72D9",
    height: "40px",
    marginTop: "20px",
    width: "100px",

    // border: "1px solid #3C72D9",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.8",
    },
    "&.Mui-disabled": {
      backgroundColor: "#e7e7e7",
      color: "white",
    },
  },
  error: {
    fontSize: "12px",
    color: "red",
    marginTop: "0.25rem",
    position: "absolute",
  },
}));

/**
A React functional component for viewing the Fee line List.
User can add/update fee lines
*/
function FeeList({ setInitialValues, values, setFieldValue, customStyles }) {
  const classes = useStyle();
  // const [orderTypes, setOrderTypes] = useState(null);
  const { orderTypes } = useContext(AuthContext);
  const [isLoading, setLoading] = useState(true);

  // const getOrderTypes = () => {
  //   Endpoint.Clients.getOrderType()
  //     .then((res) => {
  //       setOrderTypes(res.data);
  //       setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    if (orderTypes) {
      setLoading(false);
    }
  }, []);
  return (
    <div style={{}}>
      <FieldArray
        name="fee_lines"
        render={(arrayHelpers) => (
          <>
            <table className={classes.clientTable}>
              <thead className={classes.header}>
                <tr className={classes.clientthtr}>
                  <th
                    style={{
                      textAlign: "center",
                      paddingLeft: "10px",
                      paddingRight: "10px",
                      whiteSpace: "nowrap",
                    }}
                  >
                    #
                  </th>
                  <th className={classes.tableTh}>Order Type</th>
                  <th className={classes.tableTh}>Amount</th>
                  <th className={classes.tableTh}>Start Date</th>
                  <th className={classes.tableTh}>End Date</th>
                  <th
                    className={classes.tableTh}
                    style={{ paddingRight: "30px" }}
                  >
                    Options
                  </th>
                </tr>
              </thead>

              <>
                {values?.fee_lines?.map((val, key) => {
                  return (
                    <tr style={{ fontSize: "1em", color: "#4e4e4e" }}>
                      <td
                        style={{
                          textAlign: "center",
                          paddingLeft: "10px",
                          paddingRight: "10px",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {key + 1}
                      </td>
                      <td
                        className={classes.tableTdName}
                        // onClick={(e) => {
                        //   setInitialValues(val);
                        // }}
                      >
                        {orderTypes && !isLoading ? (
                          <>
                            <Select
                              // value={{ label: values.order_name }}
                              value={
                                orderTypes
                                  ? orderTypes.find(
                                      (option) =>
                                        option?.orderID === val.orderID
                                    )
                                  : ""
                              }
                              onChange={(option) =>
                                setFieldValue(
                                  `fee_lines.${key}.orderID`,
                                  option.orderID
                                )
                              }
                              getOptionLabel={(option) => option.ordertype}
                              getOptionValue={(option) => option.orderID}
                              options={orderTypes}
                              styles={customStyles}
                            />
                            <div>
                              <ErrorMessage
                                name={`fee_lines.${key}.orderID`}
                                component={FormError}
                                className={classes.error}
                              />
                            </div>
                          </>
                        ) : (
                          <Loading />
                        )}
                      </td>
                      <td className={`${classes.tableTd} ${classes.wrap}`}>
                        <FeeField
                          label={"Amount"}
                          name={`fee_lines.${key}.amount`}
                          key={key}
                          type={"text"}
                          value={val.amount}
                          setFieldValue={setFieldValue}
                        />
                      </td>
                      <td className={classes.tableTd}>
                        {" "}
                        <FeeField
                          label={"Start Date"}
                          required={true}
                          // disabled={
                          //   values?.fee_lines.length - 1 == key ? false : true
                          // }
                          type={"date"}
                          name={`fee_lines.${key}.start_date`}
                          value={val.start_date}
                          setFieldValue={setFieldValue}
                        />
                      </td>
                      <td className={classes.tableTd}>
                        {" "}
                        <FeeField
                          label={"End Date"}
                          required={true}
                          // disabled={
                          //   values?.fee_lines.length - 1 == key ? false : true
                          // }
                          type={"date"}
                          name={`val[${key}].end_date`}
                          value={val.end_date}
                          setFieldValue={setFieldValue}
                        />
                      </td>
                      <td
                        className={classes.tableTd}
                        style={{ paddingTop: "10px" }}
                      >
                        {/* <Tooltip title="Edit client">
                          <EditClient
                            className={classes.tableIcons}
                            onClick={(e) => {
                              setInitialValues(val);
                            }}
                          />
                        </Tooltip> */}
                        <Tooltip title="Delete">
                          <DeleteIcon
                            className={classes.tableIcons}
                            onClick={() => arrayHelpers.remove(key)} // remove an item from the list
                          />
                        </Tooltip>
                      </td>
                    </tr>
                  );
                })}
              </>
            </table>
            <Button
              type="button"
              className={classes.postButton}
              onClick={() => {
                let currentDate = new Date();
                currentDate = currentDate.toISOString().split("T")[0];

                if (values?.fee_lines?.length > 0) {
                  // setFieldValue(`fee_lines.${0}.end_date`, currentDate);

                  setFieldValue(
                    `fee_lines.${values.fee_lines.length - 1}.end_date`,
                    currentDate
                  );
                }

                arrayHelpers.push({
                  orderID: "",
                  amount: "",
                  start_date: currentDate,
                  end_date: "",
                });
              }}
            >
              + Fee Line
            </Button>
          </>
        )}
      />
    </div>
  );
}

export default FeeList;
