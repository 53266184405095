import React from "react";
import InvoicePage from "../../components/invoice/InvoicePage";
// import { caseData } from "../../components/invoice/data/initData";

function GeneratePdfInvoice({ caseData }) {
  return (
    <div style={{ width: "700px", margin: "96px auto 50px" }}>
      {caseData ? <InvoicePage caseData={caseData} /> : null}
    </div>
  );
}

export default GeneratePdfInvoice;
