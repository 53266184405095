import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Pagination } from "@material-ui/lab";
import globalStyles from "../helpers/GlobalStyles";
import "./SearchInvoiceTable.css";
import { getOrderType } from "../helpers/helper";
const useStyles = makeStyles(
  (theme) => ({
    paginator: {
      justifyContent: "center",
      padding: "10px",
    },
    clientTable: {
      width: "100%",
      textAlign: "left",
      // color: "#4e4e4e",
      borderCollapse: "separate",

      fontSize: "1em",
      // maxWidth: "fit-content",
      // overflow: "visible",
      // display: "block",
      // overflow: "auto",
      whiteSpace: "nowrap",
    },
    tableHead: {
      position: "sticky",
      top: 0,
      backgroundColor: "#e7e7e7",
    },
  }),
  { index: 1 }
);

/**

A React functional component for Dashboard Invoice Table.
This table is rendered when user hits Search Invoices in the Dashboard tab

*/
function UploadBatchSelectTable(props) {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const { uploadedCSVArray, setUploadedCSVArray, orderTypes } = props;
  const itemsPerPage = 10;
  const [page, setPage] = React.useState(1);
  // const [connectionId, setConncectionId]= useState(false);
  // const [noOfPages] = React.useState(
  //     Math.ceil(invoices?.length / itemsPerPage)
  // );
  var noOfPages = Math.ceil(uploadedCSVArray?.length / itemsPerPage);
  // console.log(invoices);
  // const floorFigure = (figure, decimals) => {
  //   if (!decimals) decimals = 2;
  //   const d = Math.pow(10, decimals);
  //   return (parseInt(figure * d) / d).toFixed(decimals);
  // };

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleCheckboxChange = (id) => {
    console.log(
      uploadedCSVArray.map((item) =>
        item.loanNumber === id ? { ...item, selected: !item.selected } : item
      )
    );
    setUploadedCSVArray((uploadedCSVArray) =>
      uploadedCSVArray.map((item) =>
        item.loanNumber === id ? { ...item, selected: !item.selected } : item
      )
    );
  };

  const handleSelectAll = () => {
    setUploadedCSVArray((uploadedCSVArray) =>
      uploadedCSVArray.map((item) => ({
        ...item,
        selected: uploadedCSVArray.every((item) => item.selected)
          ? !item.selected
          : true,
      }))
    );
  };

  const allSelected = uploadedCSVArray.every((item) => item.selected);

  return (
    <div style={{ maxHeight: "500px", overflow: "auto" }}>
      <table className={classes.clientTable}>
        <thead className={classes.tableHead}>
          <tr className={globalClasses.clientthtr}>
            <th className={globalClasses.tableTh}>Address</th>
            <th className={globalClasses.tableTh}>Order Type</th>
            <th className={globalClasses.tableTh}>Last Order Type</th>
            <th className={globalClasses.tableTh}>
              Process Order{" "}
              <input
                type="checkbox"
                checked={allSelected}
                onChange={() => handleSelectAll()}
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {
            //uploadedCSVArray?.slice((page - 1) * itemsPerPage, page * itemsPerPage)
            //
            uploadedCSVArray.map((csvRow, index) => (
              <tr key={index}>
                <td key={`${index}-street`} className={globalClasses.tableTd}>
                  {`${csvRow.street} ${csvRow.city} ${csvRow.state} ${csvRow.zipcode}`}
                </td>
                <td
                  key={`${index}-orderType`}
                  className={globalClasses.tableTd}
                >
                  {orderTypes?.length > 0
                    ? getOrderType(orderTypes, csvRow.orderID).ordertype
                    : "-"}
                  {}
                </td>
                <td key={`${index}-date`} className={globalClasses.tableTd}>
                  {csvRow.date ? csvRow.date.toLocaleDateString() : "-"}
                </td>
                <td key={`${index}-selected`} className={globalClasses.tableTd}>
                  <input
                    type="checkbox"
                    checked={csvRow.selected}
                    onChange={() => handleCheckboxChange(csvRow.loanNumber)}
                  />
                </td>
              </tr>
            ))
          }
        </tbody>
      </table>
    </div>
  );
}
export default UploadBatchSelectTable;
