import React from "react";
import { Input, makeStyles } from "@material-ui/core";
import { Field, ErrorMessage } from "formik";
import FormError from "../FormError";

import FMUInputField from "./FormIkMUFormComponent/FMUInputField";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  parentChild: {
    height: "50px",
    flex: "1",
    width: "100%",
    "&:nth-child(1)": {
      paddingRight: "5vh",
    },
    "&:nth-child(2)": {
      paddingLeft: "5vh",
    },
  },
  rowContainer: {
    display: "flex",
    marginTop: "8px",
  },
  labelContainer: {
    display: "flex",
    width: "50%",
    alignItems: "center",
    "& p": {
      fontSize: ".9em",
      // // fontWeight:"bold",
      color: "black",
    },
  },
  fieldContainer: {
    width: "100%",
  },
  input: {
    width: "95%",

    "& .MuiInputBase-input": {
      textIndent: "10px",
      textTransform: "capitalize",
      textAlign: "start",
    },
    height: "40px",
    paddingLeft: "3px",
    borderBottom: "1px solid #BABABA",
  },
  emailInput: {
    width: "95%",
    "& .MuiInputBase-input": {
      textIndent: "10px",
      textAlign: "start",
    },
    height: "40px",
    paddingLeft: "3px",

    // borderRadius: "10px",
    // border: "1px solid #9dc8d2",
    // backgroundColor: "#f8f8f8",
    borderBottom: "1px solid #BABABA",
  },
  dateInput: {
    width: "95%",
    "& .MuiInputBase-input": {
      textIndent: "10px",
      textAlign: "start",
    },
    height: "40px",
    paddingLeft: "3px",
    borderBottom: "1px solid #BABABA",
  },
  error: {
    fontSize: "12px",
    color: "red",
    marginTop: "0.25rem",
    // paddingLeft: "50%",
  },
}));
function FeeField({
  label,
  name,
  key,
  type,
  disabled,
  placeHolder,
  isEmail,
  required,
  input,
  customOnChange,
  style,
  value,
  setFieldValue,
}) {
  const handleOnChange = (e) => {
    return customOnChange(e);
  };
  const classes = useStyles();
  return (
    <div className={classes.parentChild}>
      <div className={classes.rowContainer}>
        {/* <div className={classes.labelContainer}>
          {required ? (
            <Typography>
              {label}
              <span style={{ color: "red", fontWeight: 500 }}>*</span>
            </Typography>
          ) : (
            <Typography>{label}</Typography>
          )}
        </div> */}
        <div className={classes.fieldContainer}>
          {input ? (
            <Input
              name={name}
              type={type}
              disabled={disabled}
              fullwidth={true}
              // onChange={handleOnChange}

              onChange={(e) => {
                setFieldValue(name, e.target.value);
              }}
              disableUnderline={true}
              className={
                isEmail
                  ? classes.emailInput
                  : type === "date"
                  ? classes.dateInput
                  : classes.input
              }
              label={placeHolder ? placeHolder : label}
              value={value}
            />
          ) : (
            <Field
              name={name}
              type={type}
              disabled={disabled}
              fullwidth={true}
              style={style}
              disableUnderline={true}
              className={
                isEmail
                  ? classes.emailInput
                  : type === "date"
                  ? classes.dateInput
                  : classes.input
              }
              component={FMUInputField}
              label={placeHolder ? placeHolder : label}
              value={value}
              onChange={(e) => {
                setFieldValue(name, e.target.value);
              }}
            />
          )}
        </div>
      </div>
      <div>
        <ErrorMessage
          name={name}
          component={FormError}
          className={classes.error}
        />
      </div>
    </div>
  );
}

export default FeeField;
