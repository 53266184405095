import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  makeStyles,
  Typography,
  Input,
} from "@material-ui/core";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { useSnackbar } from "notistack";
import InputMask from "react-input-mask";

import Select from "react-select";
import { array, date, number, object, string } from "yup";
import Endpoint from "../../endpoint";
// import OpenDialogIcon from "../../icons/OpenDialog";
import { states } from "../../helpers/states";
import ClientField from "../../components/shared/InputField";
import FMUInputField from "../../components/shared/FormIkMUFormComponent/FMUInputField";
// import ContactsDialog from "./ContactsDialog";
import { generateQuickGuid, getTaxIDMask } from "../../helpers/helper";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MaskField from "../../components/shared/MaskField";
import FeeTab from "./FeeTab";

import { initialState } from "./clientInitialState";
import Loading from "../Loading";

import globalStyles from "../../helpers/GlobalStyles";
import { CLIENT } from "../../types/routeConstants";
// import ArrowBackIosNewIcon from "@material-ui/icons/ArrowBackIosNewIcon";

const useStyle = makeStyles((theme) => ({
  container: {
    paddingTop: "calc(5vh + 60px)",
    paddingLeft: "2%",
    paddingRight: "2%",
  },
  caseContainer: {
    // width: "928px",
    width: "98%",
    paddingLeft: "1%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
      paddingLeft: "1%",
    },
  },
  topBar: {
    height: "50px",
    // backgroundColor: "#f8f8f8",
    padding: "0 0 0 1%",
    display: "flex",
    alignItems: "center",
    // marginTop: "6vh",
    justifyContent: "space-between",
  },
  heading: {
    fontSize: "0.9em",
    color: theme.palette.primary.main,
    fontWeight: "bold",
  },
  parent: {
    justifyContent: "space-between",
    flexDirection: "row",
    flexWrap: "wrap",
    display: "flex",
    paddingRight: "2%",
    marginTop: "2vh",
  },
  recordingBtn: {
    border: "1px solid rgba(0, 151, 187, 1)",
    height: "42px",
    borderRadius: "10px",
    minWidth: "0px",
    width: "48px",

    "&:hover": {
      opacity: 0.7411764705882353,
      backgroundColor: "#0097BB",
      color: "black",
      "& svg": {
        color: "#fff",
      },
    },
  },
  parentChild: {
    flex: "1",
    width: "40%",
    "&:nth-child(1)": {
      paddingRight: "5vh",
    },
    "&:nth-child(2)": {
      paddingLeft: "5vh",
    },
  },
  rowContainer: {
    display: "flex",
    marginTop: "8px",
  },
  labelContainer: {
    display: "flex",
    width: "50%",
    alignItems: "center",
    "& p": {
      fontSize: "0.9em",
      // // fontWeight:"bold",
      color: "black",
    },
  },
  fieldContainer: {
    width: "50%",
    textAlign: "end",
  },
  postButton: {
    backgroundColor: theme.palette.primary.main,
    // fontFamily: "poppins",
    //fontWeight: "bold",
    fontSize: ".8em",
    color: "#ffffff",
    textTransform: "inherit",
    // borderRadius: "5px",
    boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
    textAlign: "center",
    marginRight: "2%",
    border: "1px solid #3C72D9",
    // height: "30px",
    marginTop: "20px",
    width: "120px",
    // border: "1px solid #3C72D9",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.8",
    },
    "&.Mui-disabled": {
      backgroundColor: "#e7e7e7",
      color: "white",
    },
  },
  error: {
    fontSize: "12px",
    color: "red",
    marginTop: "0.25rem",
    paddingRight: "2.5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  backButton: {
    border: "1px solid",
    padding: "5px",
    marginRight: "20px",
  },
  addClientButton: {
    boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
    backgroundColor: "#f8f8f8",
    borderRadius: "20px",
    textTransform: "capitalize",
  },
}));

const customStyles = {
  textAlign: "end",
  control: (base, state) => ({
    ...base,
    borderRadius: null,
    // Overwrittes the different states of border
    borderColor: null,

    borderStyle: null,
    borderBottom: "1px solid #BABABA",
    // Removes weird border around container
    boxShadow: null,
  }),
};

/**
A React functional component for Editing or Adding a client.
User can also move to the Fee tab from here
*/
function AddClient() {
  const classes = useStyle();
  const globalClasses = globalStyles();

  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const [dialog, setDialog] = useState(false);
  const [fee, setFee] = useState(false);
  const formRef = useRef();

  const [loading, setLoading] = useState(true);

  const [initState, setInitState] = useState(initialState);

  useEffect(() => {
    if (location.pathname.split("/").pop() == "fee") {
      setFee(true);
    }
  }, []);

  // Function for retrieving a single client's information using API Endpoint
  const getClient = (id) => {
    Endpoint.Clients.getClient(id)
      .then((res) => {
        setInitState(res.data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    // If no id in params then Adding new client - set loading to false without any other steps
    if (initState && !id) {
      setLoading(false);
    }
    // If id exists in params then editing existing client - set loading to false after fetching client
    if (id) {
      getClient(id);
    }
  }, [initState]);

  // Handle Submit Function for adding/updating a client
  const handleSubmit = (v) => {
    let obj;
    let message;
    if (v.client_id === "") {
      obj = {
        client_id: generateQuickGuid("client"),
        client_name: v.client_name,
        street_address: v.street_address_line2
          ? `${v.street_address} ${v.street_address_line2}`
          : `${v.street_address}`,
        city: v.city,
        zip_code: v.zip_code,
        tax_id: v.tax_id,
        state: v.state,
        attention: v.attention,
        start_date: v.start_date,
        end_date: v.end_date,
        fee_lines: v.fee_lines,
      };
      message = "Client added successfully!";
    } else {
      obj = {
        client_id: v.client_id,
        client_name: v.client_name,
        street_address: v.street_address_line2
          ? `${v.street_address} ${v.street_address_line2}`
          : `${v.street_address}`,
        city: v.city,
        zip_code: v.zip_code,
        tax_id: v.tax_id,
        state: v.state,
        attention: v.attention,
        start_date: v.start_date,
        end_date: v.end_date,
        fee_lines: v.fee_lines,
      };
      message = "Client updated successfully";
    }
    console.log(obj);
    Endpoint.Clients.addClient(obj)
      .then((res) => {
        enqueueSnackbar(message, {
          variant: "success",
        });
        navigate(CLIENT.path);
        // window.location.reload();
        // setRefresh(true);
        // SetView(!view);
      })
      .catch((err) => {
        enqueueSnackbar("Sorry something went wrong, please try again later.", {
          variant: "error",
        });
      });
  };
  const validateSchema = object().shape({
    client_name: string().required("Client name is required"),
    street_address: string().required("Street address is required"),
    city: string().required("City is required"),
    zip_code: string().required("Zipcode is required"),
    tax_id: string().required("Tax ID is required"),
    state: string().required("State is required"),
    attention: string().required("Attention is required"),
    start_date: date().required("Start Date is required"),
    // attention: number().moreThan(0),
  });

  if (loading) return <Loading pageLoading={true} left={"60%"} />;
  return (
    <div className={`${globalClasses.root}`}>
      <div className={`${globalClasses.flexColumn}`}>
        <div className={classes.container}>
          <div>
            {!fee ? (
              <>
                <div className={classes.topBar}>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <IconButton
                      className={classes.backButton}
                      onClick={() => {
                        navigate(CLIENT.path);
                      }}
                    >
                      <ArrowBackIosNewIcon fontSize="small" />
                    </IconButton>
                    <span className={classes.heading}>
                      {initState.client_id === ""
                        ? "Add Client"
                        : "Edit Client Profile"}
                    </span>
                  </span>
                  {initState.client_id === "" ? (
                    <></>
                  ) : (
                    <Button
                      className={classes.addClientButton}
                      style={
                        fee
                          ? { backgroundColor: "#4e4e4e", color: "white" }
                          : null
                      }
                      onClick={(e) => {
                        setFee(!fee);
                        navigate(
                          `${CLIENT.childPathEdit}/${id}${CLIENT.childPathFee}`
                        );
                      }}
                    >
                      Fee
                    </Button>
                  )}
                </div>

                <div className={classes.caseContainer}>
                  <Formik
                    enableReinitialize={true}
                    initialValues={initState}
                    validationSchema={validateSchema}
                    validateOnBlur={true}
                    innerRef={formRef}
                    // validateOnChange
                    onSubmit={(e) => {
                      handleSubmit(e);
                    }}
                    render={({
                      isSubmitting,
                      dirty,
                      touched,
                      values,
                      errors,
                      setFieldValue,
                    }) => (
                      <Form>
                        {/* {console.log(values)} */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div style={{ flex: "1" }}>
                            <div
                              className={classes.parent}
                              // style={{ marginTop: "8vh" }}
                            >
                              <ClientField
                                label={"Client Name"}
                                required={true}
                                // disabled={initState.client_id !== "" ? true : false}
                                type={"text"}
                                name={"client_name"}
                              />
                            </div>
                            <div className={classes.parent}>
                              <ClientField
                                label={"Street Address"}
                                required={true}
                                type={"text"}
                                name={"street_address"}
                                placeHolder={"line 1"}
                              />
                            </div>
                            <div className={classes.parent}>
                              <ClientField
                                type={"text"}
                                name={"street_address_line2"}
                                placeHolder={"line 2"}
                              />
                            </div>

                            <div className={classes.parent}>
                              <ClientField
                                label={"City"}
                                required={true}
                                type={"text"}
                                name={"city"}
                              />
                            </div>
                            <div className={classes.parent}>
                              <div className={classes.parentChild}>
                                <div className={classes.rowContainer}>
                                  <div className={classes.labelContainer}>
                                    <Typography>
                                      State
                                      <span
                                        style={{
                                          color: "red",
                                          fontWeight: 500,
                                        }}
                                      >
                                        *
                                      </span>
                                    </Typography>
                                  </div>
                                  <div className={classes.fieldContainer}>
                                    <Select
                                      value={{ label: values.state }}
                                      onChange={(e) => {
                                        setFieldValue("state", e.value, false);
                                      }}
                                      options={states}
                                      styles={customStyles}
                                      style={
                                        {
                                          // width: "95%",
                                          // border: "1px solid #9dc8d2",
                                          // height: "40px",
                                          // paddingLeft: "3px",
                                          // borderRadius: "10px",
                                          // backgroundColor: "#f8f8f8",
                                        }
                                      }
                                    />
                                    <ErrorMessage name={`state`}>
                                      {(msg) => (
                                        <div className={classes.error}>
                                          {msg}
                                        </div>
                                      )}
                                    </ErrorMessage>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className={classes.parent}>
                              <ClientField
                                label={"Zip"}
                                required={true}
                                type={"text"}
                                name={"zip_code"}
                              />
                            </div>
                          </div>

                          <div style={{ flex: "1" }}>
                            <div
                              className={classes.parent}
                              // style={{ marginTop: "8vh" }}
                            >
                              <ClientField
                                label={"Attention"}
                                required={true}
                                type={"text"}
                                name={"attention"}
                              />
                            </div>

                            <div className={classes.parent}>
                              <MaskField
                                label={"Tax ID"}
                                required={true}
                                placeholder={"Tax ID"}
                                disabled={
                                  initState.client_id !== "" ? true : false
                                }
                                mask={getTaxIDMask()}
                                setFieldValue={setFieldValue}
                                name={"tax_id"}
                                value={values.tax_id}
                              />
                            </div>
                            <div className={classes.parent}>
                              <ClientField
                                label={"Start Date"}
                                required={true}
                                type={"date"}
                                name={"start_date"}
                              />
                            </div>
                            <div className={classes.parent}>
                              <ClientField
                                label={"End Date"}
                                type={"date"}
                                name={"end_date"}
                              />
                            </div>
                          </div>
                        </div>
                        <Button
                          className={classes.postButton}
                          style={{ marginTop: "40px", float: "right" }}
                          type={"submit"}
                        >
                          {initState.client_id === ""
                            ? "Add Client"
                            : "Update Client"}
                        </Button>

                        {/* <ContactsDialog
                dialog={dialog}
                handleCloseDialog={(e) => {
                  setDialog(false);
                }}
                values={values}
                setFieldValue={setFieldValue}
              /> */}
                      </Form>
                    )}
                  />
                </div>
              </>
            ) : (
              <FeeTab
                setFee={setFee}
                initState={initState}
                getClient={getClient}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddClient;
