import React from "react";
import { Input, makeStyles } from "@material-ui/core";
import { Field, ErrorMessage } from "formik";
import FormError from "../FormError";

import FMUInputField from "./FormIkMUFormComponent/FMUInputField";
import { Typography } from "@material-ui/core";
import Select from "react-select";

const useStyles = makeStyles((theme) => ({
  parentChild: {
    height: "50px",
    flex: "1",
    width: "100%",
    "&:nth-child(1)": {
      paddingRight: "5vh",
    },
    "&:nth-child(2)": {
      paddingLeft: "5vh",
    },
  },
  rowContainer: {
    display: "flex",
    marginTop: "8px",
  },
  labelContainer: {
    display: "flex",
    width: "50%",
    alignItems: "center",
    "& p": {
      fontSize: "0.9em",
      // // fontWeight:"bold",
      color: "black",
    },
  },
  fieldContainer: {
    // width: "50%",
    minWidth: "15%",
    maxWidth: "100%",
  },
  input: {
    width: "95%",

    "& .MuiInputBase-input": {
      textIndent: "10px",
      textTransform: "capitalize",
      textAlign: "end",
    },
    height: "40px",
    paddingLeft: "3px",
    borderBottom: "1px solid #BABABA",
  },
  emailInput: {
    width: "95%",
    "& .MuiInputBase-input": {
      textIndent: "10px",
      textAlign: "end",
    },
    height: "40px",
    paddingLeft: "3px",

    // borderRadius: "10px",
    // border: "1px solid #9dc8d2",
    // backgroundColor: "#f8f8f8",
    borderBottom: "1px solid #BABABA",
  },
  dateInput: {
    width: "95%",
    "& .MuiInputBase-input": {
      textIndent: "10px",
      textAlign: "end",
    },
    height: "40px",
    paddingLeft: "3px",
    borderBottom: "1px solid #BABABA",
  },
  error: {
    fontSize: "12px",
    color: "red",
    marginTop: "0.25rem",
    paddingRight: "2.5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
}));

const customStyles = {
  textAlign: "end",
  control: (base, state) => ({
    ...base,
    borderRadius: null,
    // Overwrittes the different states of border
    borderColor: null,

    borderStyle: null,
    borderBottom: "1px solid #BABABA",
    // Removes weird border around container
    boxShadow: null,
  }),
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
};

function SelectFilterField({
  placeholder,
  inputValue,
  inputLabel,
  options,
  selectedColumns,
  setSelectedColumns,
}) {
  const classes = useStyles();

  function handleSelectChange(selectedColumns) {
    setSelectedColumns(selectedColumns);
  }

  function filterOptions(option) {
    return !selectedColumns.includes(option.value);
  }

  const filteredOptions = options.filter(filterOptions);
  // const filteredOptions = options.filter(filterOptions).map((option) => ({
  //   value: option.inputValue,
  //   label: option.inputLabel,
  // }));
  return (
    <div className={classes.parentChild}>
      <div className={classes.rowContainer}>
        <div className={classes.fieldContainer}>
          <Select
            options={filteredOptions}
            isMulti
            // value={selectedColumns.map((value) => ({
            //   value,
            //   label: options.find((option) => option.value === value)
            //     .customLabel,
            // }))}
            value={selectedColumns}
            onChange={handleSelectChange}
            menuPortalTarget={document.body}
            styles={customStyles}
            placeholder={placeholder}
            // getOptionLabel={(option) => option[inputLabel]}
            // getOptionValue={(option) => option[inputValue]}
            style={
              {
                // width: "95%",
                // border: "1px solid #9dc8d2",
                // height: "40px",
                // paddingLeft: "3px",
                // borderRadius: "10px",
                // backgroundColor: "#f8f8f8",
              }
            }
          />
        </div>
      </div>
      {/* <div>
        <ErrorMessage
          name={name}
          component={FormError}
          className={classes.error}
        />
      </div> */}
    </div>
  );
}

export default SelectFilterField;
