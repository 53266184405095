import React, { useState, useContext } from "react";
import { AuthContext } from "../../auth/AuthWrapper";
import { makeStyles } from "@material-ui/styles";
import { useSnackbar } from "notistack";
import globalStyles from "../../helpers/GlobalStyles";
import InvoiceFilterForm from "../../dialogs/InvoiceFilterForm";
import SearchInvoiceTable from "../../components/SearchInvoiceTable";
import CsvDownload from "react-json-to-csv";
// import FileDownloadIcon from "@mui/icons-material/FileDownload";
// import InvoicePage from "../../components/invoice/InvoicePage";
import { caseData } from "../../components/invoice/data/initData";
import GeneratePdfInvoice from "./GeneratePdfInvoice";
import invoiceSampleData from "../../helpers/invoiceSampleData.json";
import Loading from "../Loading";
const useStyles = makeStyles(
  (theme) => ({
    invoiceFormContainer: {
      width: "100%",
      paddingTop: "calc(3vh + 60px)",
      paddingLeft: "5vh",
      paddingRight: "2vh",
      marginRight: "50vh",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
    },
    rightSide: {
      width: "100%",
    },
    csvDownload: {
      padding: "10px",
      borderRadius: "20px",
      cursor: "pointer",
      color: "white",
      backgroundColor: "#147CFF",
      border: "0px",
      boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
      height: "35px",
      alignSelf: "end",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: "0.8",
        color: "white",
      },
    },
  }),
  { index: 1 }
);

// Sample Column Names for the Searched Invoices
const columnNames = [
  // { label: "#", value: "" },
  { label: "Request Id", value: "requestid" },
  { label: "Loan Number", value: "loannumber" },
  { label: "First Name", value: "first_name" },
  { label: "Last Name", value: "last_name" },
  { label: "Input Street", value: "input_street" },
  { label: "Input City", value: "input_city" },
  { label: "Input State", value: "input_state" },
  { label: "Input Zip", value: "input_zipcode" },
  { label: "Current Street", value: "current_street" },
  { label: "Current City", value: "current_city" },
  { label: "Current State", value: "current_state" },
  { label: "Current Zip", value: "current_zipcode" },
  { label: "Current Age", value: "current_age" },
  { label: "Deceased Flag", value: "deceased" },
  { label: "Occupancy Status", value: "prediction" },
];

/**

A React functional component for Invoices
It provides functionality for the Search invoice feature. Admin can add search filters
Admin can also see the invoice table.
Admin can either, download, excel/PDF from the table results

*/
function Invoices() {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const { user, isLoading } = useContext(AuthContext);
  const { enqueueSnackbar } = useSnackbar();
  // State to show search invoice fields and search invoice table
  const [searchInvoiceView, setSearchInvoiceView] = useState(true);

  // State to show PDF generate mode
  const [generatePdf, setGeneratePdf] = useState(false);

  // State for the Searched Invoices List, selectColumns
  const [invoices, setInvoices] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);

  // Handler function to update searched invoices list after search button clicked
  const handleInvoiceListSearch = (values, filteredValues) => {
    //Test data
    const updatedInvoiceSampleData = invoiceSampleData.map((item) => ({
      ...item,
      selected: false,
    }));

    // console.log(updatedInvoiceSampleData);
    setInvoices(updatedInvoiceSampleData);
    setFilteredInvoices(updatedInvoiceSampleData);
    // setInvoices(values);
    // setFilteredInvoices(filteredValues);
    enqueueSnackbar("Invoices fetched!", {
      variant: "success",
    });
    return;
  };

  // function to filter searched invoices list with selected is true or when nothing is selected
  const filterInvoices = () => {
    const filteredInvoices = invoices.filter((item) => item.selected === true);
    if (filteredInvoices.length > 0) {
      return filteredInvoices;
    } else {
      return invoices;
    }
  };

  if (isLoading) return <Loading pageLoading={true} />;
  return (
    <div>
      {searchInvoiceView ? (
        <div
          className={`${classes.invoiceFormContainer} ${globalClasses.flexRow}`}
        >
          <div className={`${classes.rightSide} ${globalClasses.flexColumn}`}>
            <InvoiceFilterForm
              invoiceListSearch={handleInvoiceListSearch}
              clientName={user.clientName}
              placeholder={"Filter Columns"}
              inputValue={"value"}
              inputLabel={"label"}
              options={columnNames}
              selectedColumns={selectedColumns}
              setSelectedColumns={setSelectedColumns}
            />
            {invoices.length > 0 ? (
              <>
                <div
                  style={{
                    alignSelf: "end",
                    marginTop: "3.5rem",
                    marginBottom: "1rem",
                  }}
                >
                  <CsvDownload
                    className={classes.csvDownload}
                    data={filterInvoices()}
                  />
                </div>
                <div style={{ overflow: "auto", marginBottom: "50px" }}>
                  <SearchInvoiceTable
                    invoices={invoices}
                    setInvoices={setInvoices}
                    isAdmin={user.isAdmin}
                    columnNames={
                      selectedColumns.length > 0 ? selectedColumns : columnNames
                    }
                  />
                </div>
              </>
            ) : null}
          </div>
        </div>
      ) : null}
      {generatePdf ? (
        <>{caseData ? <GeneratePdfInvoice caseData={caseData} /> : null}</>
      ) : null}
    </div>
  );
}

// const dashboardFilterBoxProps = {
//   bgcolor: "background.paper",
//   color: "#147CFF",
//   m: 2,
//   border: 2,
//   style: {
//     width: "40rem",
//     height: "14rem",
//     paddingTop: "20px",
//     borderRadius: "20px",
//     display: "flex",
//     margin: "auto",
//   },
// };
// const currentUsernameProps = {
//   m: 1,
//   // border: 2,
//   style: {
//     width: "8.5rem",
//     height: "0.2rem",
//     alignContents: "center",
//     display: "inline-table",
//     margin: 0,
//     marginLeft: "23%",
//     paddingRight: "5px",
//     paddingLeft: "5px",
//   },
// };

export default Invoices;
