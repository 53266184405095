import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/styles";
import { Pagination } from "@material-ui/lab";
import globalStyles from "../../helpers/GlobalStyles";

import FileField from "./FileField";
import Select from "react-select";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import { Button, Typography } from "@material-ui/core";

import { generateQuickGuid, getOrderType } from "../../helpers/helper";
import CsvDownload from "react-json-to-csv";

import NewOrderDialog from "./NewOrderDialog";

// import "./WorkOrderTable.css";
const useStyles = makeStyles(
  (theme) => ({
    content: {
      width: "100%",
      paddingTop: "calc(9vh + 100px)",
      paddingLeft: "14vh",
      marginRight: "50vh",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
    },
    updateBatchContent: {
      width: "100%",
      paddingTop: "calc(30vh + 100px)",
      paddingLeft: "30vh",
      marginRight: "14vh",
      fontFamily: "Helvetica Neue",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
    },
    actions: {
      alignItems: "center",
      marginBottom: "10px",
    },
    actionsUpdateBatch: {
      alignItems: "center",
      marginBottom: "10px",
      // marginTop: "200px",
      fontFamily: "Helvetica Neue",
    },
    contentTitle: {
      color: "#0D8F75",
      marginBottom: "10px",
      fontSize: "25px",
    },
    actionButton: {
      width: "300px",
      height: "60px",
      // top: "200px",
      padding: 0,
      color: "#FFFFF",
      marginLeft: "32px",
      backgroundColor: "#0D8F75",
      borderRadius: "50px",
      "&:hover": {
        backgroundColor: "#0D8F75",
      },
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
      },
    },
    actionButtonAgain: {
      width: "200px",
      height: "35px",
      float: "right",
      padding: 0,
      color: "#FFFFF",
      marginLeft: "32px",
      backgroundColor: "#0D8F75",
      borderRadius: "50px",
      "&:hover": {
        backgroundColor: "#0D8F75",
      },
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
      },
    },
    actionUsernameButton: {
      width: "200px",
      height: "35px",
      float: "right",
      padding: 0,
      color: "#FFFFF",
      marginLeft: "32px",
      // marginTop: "10px",
      backgroundColor: "#0D8F75",
      borderRadius: "50px",
      "&:hover": {
        backgroundColor: "#0D8F75",
      },
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
      },
    },
    actionPasswordButton: {
      width: "200px",
      height: "35px",
      float: "right",
      padding: 0,
      color: "#FFFFF",
      marginLeft: "32px",
      marginTop: "10px",
      backgroundColor: "#0D8F75",
      borderRadius: "50px",
      "&:hover": {
        backgroundColor: "#0D8F75",
      },
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
      },
    },
    actionUserButton: {
      width: "200px",
      height: "35px",
      float: "right",
      padding: 0,
      color: "#FFFFF",
      marginLeft: "32px",
      marginTop: "10px",
      backgroundColor: "#0D8F75",
      borderRadius: "50px",
      "&:hover": {
        backgroundColor: "#0D8F75",
      },
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
      },
    },
    uploadBatchText: {
      textAlign: "center",
      fontFamily: "Helvetica Neue",
      color: "#0D8F75",
      marginBottom: "50px",
    },
    rightSide: {
      width: "100%",
    },
    form: {
      width: "30vh",
      minWidth: "250px",
      background: "#0D8F75",
      padding: "25px",
      alignSelf: "flex-start",
      borderRadius: "3vh",
    },
    input: {
      height: "30px",
      width: "100%",
      padding: theme.spacing(2),
      marginTop: "3px",
      borderRadius: "30px",
      fontSize: "14px",
      backgroundColor: "#FFFFFF",
    },
    button: {
      width: "120px",
      height: "30px",
      fontSize: "16px",
      margin: "auto",
      marginTop: "12px",
      textTransform: "none",
    },
    field: {
      width: "100%",
      margin: "auto",
      marginTop: "12px",
      "& label": {
        fontSize: "18px",
        color: "#FFFFFF",
      },
    },
    buttonField: {
      marginTop: 0,
    },
    paginator: {
      justifyContent: "center",
      padding: "10px",
    },
    selectedSearchAddress: {
      color: "#0D8F75",
      fontWeight: 800,
    },
    selectedUpdateBatch: {
      color: "#0D8F75",
      fontWeight: 800,
    },
    selectedAccount: {
      color: "#0D8F75",
      fontWeight: 800,
      // width: "50px !important",
    },
    accountContent: {
      width: "100%",
      paddingTop: "calc(30vh + 100px)",
      paddingLeft: "10vh",
      marginRight: "16vh",
      WebkitBoxSizing: "border-box",
      MozBoxSizing: "border-box",
      boxSizing: "border-box",
    },
    dialogInput: {
      width: "40%",
    },
    postButton: {
      backgroundColor: theme.palette.primary.main,
      // fontFamily: "poppins",
      //fontWeight: "bold",
      fontSize: ".8em",
      backgroundColor: "#f8f8f8",
      textTransform: "inherit",
      // borderRadius: "5px",
      boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
      textAlign: "center",
      marginRight: "2%",
      // border: "1px solid #3C72D9",
      // height: "30px",
      // marginTop: "20px",
      width: "120px",
      // border: "1px solid #3C72D9",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: "0.8",
        color: "white",
      },
      "&.Mui-disabled": {
        backgroundColor: "#e7e7e7",
        color: "white",
      },
    },
    tableTh: {
      textAlign: "center",
      paddingLeft: "10px",
      paddingRight: "10px",
      whiteSpace: "nowrap",
      // minWidth: "60px",
    },
    csvDownload: {
      padding: "10px",
      borderRadius: "20px",
      cursor: "pointer",
      color: "white",
      backgroundColor: "#147CFF",
      border: "0px",
      boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
      height: "35px",
      alignSelf: "end",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: "0.8",
        color: "white",
      },
    },
  }),
  { index: 1 }
);
const customStyles = {
  textAlign: "end",
  // option: (styles) => ({ ...styles, width: "100px" }),
  menu: (styles) => ({ ...styles, width: "200px" }),
  valueContainer: (provided, state) => ({
    ...provided,
    // width: "20px",
    // marginBottom: "10px",
    // paddingBottom: "1px",
    // paddingTop: "10px",
    padding: "2px 8px 2px 0px",
    // paddingRight:
  }),
  input: (styles) => ({ ...styles, margin: "0px" }),
  control: (base, state) => ({
    ...base,
    // width: "50px",
    maxHeight: "20px",
    minHeight: "20px",
    borderRadius: null,
    // Overwrittes the different states of border
    borderColor: null,

    borderStyle: null,
    borderBottom: null,
    // Removes weird border around container
    boxShadow: null,
  }),
};
const columnNames = [
  {
    label: "Work Order",
    value: "work_order",
    type: "text",
  },
  {
    label: "Request Date",
    value: "request_date",
    type: "date",
  },
  {
    label: "Order Type",
    value: "order_type",
    type: "select",
  },

  {
    label: "Completed Date",
    value: "completed_date",
    type: "date",
  },
  {
    label: "Invoice Date",
    value: "invoice_date",
    type: "date",
  },
  {
    label: "Invoice #",
    value: "invoice_number",
    type: "number",
  },
  {
    label: "Invoice Status",
    value: "invoice_status",
    type: "text",
  },
  {
    label: "Paid",
    value: "paid",
    type: "text",
  },
  {
    label: "Amount",
    value: "amount",
    type: "number",
    icon: "$",
  },
  {
    label: "Check Date",
    value: "check_date",
    type: "date",
  },
  {
    label: "Check #",
    value: "check_no",
    type: "number",
  },
];

const occupancyDropdown = [
  { label: "None", value: "", chipLabel: "" },
  { label: "Unknown", value: "Unknown", chipLabel: "UN" },
  { label: "Vacant", value: "Vacant", chipLabel: "VC" },
  { label: "Occupied", value: "Occupied", chipLabel: "OC" },
];

/**

A React functional component for rendering the Work orders
Option to add new work order req.

*/
function WorkOrderTable(props) {
  const classes = useStyles();
  const globalClasses = globalStyles();

  const { workOrders, setFieldValue, orderTypes } = props;

  const [selectedOrderType, setSelectedOrderType] = useState(1);
  const itemsPerPage = 10;
  const [page, setPage] = React.useState(1);
  const [showNewOrderDialog, setShowNewOrderDialog] = useState(false); // state to toggle dialog box visibility for requesting a new Work Order

  // const [connectionId, setConncectionId]= useState(false);
  // const [noOfPages] = React.useState(
  //     Math.ceil(statusList?.length / itemsPerPage)
  // );
  var noOfPages = Math.ceil(workOrders?.length / itemsPerPage);
  // console.log(statusList);
  // const floorFigure = (figure, decimals) => {
  //   if (!decimals) decimals = 2;
  //   const d = Math.pow(10, decimals);
  //   return (parseInt(figure * d) / d).toFixed(decimals);
  // };

  // const handleChange = (event, value) => {
  //   setPage(value);
  // };

  const openNewOrderDialog = () => {
    setShowNewOrderDialog(true);
  };

  const handleCloseNewOrderDialog = () => {
    setShowNewOrderDialog(false);
  };
  useEffect(() => {
    // console.log(openNewOrderDialog);
  }, [openNewOrderDialog]);

  // handle single row checkbox select
  const handleCheckboxChange = (id, index) => {
    setFieldValue(`work_orders[${index}]`, {
      ...workOrders[index],
      selected: !workOrders[index].selected,
    });
  };

  // handle ALL checkbox select
  const handleSelectAll = () => {
    setFieldValue(
      `work_orders`,
      workOrders.map((item) => ({
        ...item,
        selected: workOrders?.every((item) => item.selected)
          ? !item.selected
          : true,
      }))
    );
  };

  const allSelected = workOrders?.every((item) => item.selected);
  const anySelected = workOrders?.some((item) => item.selected);

  return (
    <FieldArray
      name="work_orders"
      render={(arrayHelpers) => (
        <div
          style={{
            width: "98%",
          }}
        >
          <NewOrderDialog
            showNewOrderDialog={showNewOrderDialog}
            handleCloseNewOrderDialog={handleCloseNewOrderDialog}
            arrayHelpers={arrayHelpers}
            selectedOrderType={selectedOrderType}
          />
          <div>
            <span
              style={{
                display: "flex",
                justifyContent: "space-between",
                // marginTop: "3.5rem",
              }}
            >
              <div>
                <Typography variant="subtitle1" style={{ fontWeight: 800 }}>
                  Create a New Work Order Request
                </Typography>
                <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
                  Order Type
                </Typography>
                <div
                  style={{
                    display: "flex",
                  }}
                >
                  <Select
                    // value={{ label: values.order_name }}
                    value={
                      orderTypes
                        ? orderTypes.find(
                            (option) => option?.orderID === selectedOrderType
                          )
                        : ""
                    }
                    onChange={(option) => setSelectedOrderType(option.orderID)}
                    getOptionLabel={(option) => option.ordertype}
                    getOptionValue={(option) => option.orderID}
                    options={orderTypes}
                    styles={customStyles}
                  />
                  <Button
                    className={classes.postButton}
                    style={{ marginLeft: "20px" }}
                    onClick={() => {
                      openNewOrderDialog();
                    }}
                  >
                    Place New Order
                  </Button>
                </div>
              </div>
              <CsvDownload
                className={classes.csvDownload}
                disabled={workOrders?.length == 0}
                data={
                  anySelected
                    ? workOrders?.filter((obj) => obj.selected)
                    : workOrders
                }
              />
            </span>
          </div>

          <div>
            <div
              style={{
                overflowX: "auto",
              }}
            >
              <table className={globalClasses.clientTable}>
                <thead className={globalClasses.tableHead}>
                  <tr className={globalClasses.clientthtr}>
                    <th
                      style={{
                        textAlign: "center",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {" "}
                      {/* Select All */}
                      <input
                        type="checkbox"
                        checked={allSelected}
                        onChange={() => {
                          handleSelectAll();
                        }}
                      />
                    </th>
                    <th
                      style={{
                        textAlign: "center",
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      #
                    </th>
                    {columnNames.map((columnName) => (
                      <th className={globalClasses.tableTh}>
                        {columnName.label}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {workOrders
                    ?.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                    .map((workOrder, index) => (
                      <tr key={index}>
                        <td
                          style={{
                            textAlign: "center",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={workOrder.selected}
                            onChange={() =>
                              handleCheckboxChange(workOrder.work_order, index)
                            }
                          />
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            paddingLeft: "10px",
                            paddingRight: "10px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {index + 1}
                        </td>
                        {columnNames.map((columnName) => (
                          <>
                            <td className={classes.tableTh}>
                              {workOrder[columnName.value] ? (
                                <>
                                  {columnName.type == "date" ? (
                                    <p>
                                      {new Date(
                                        workOrder[columnName.value]
                                      ).toLocaleDateString("en-US", {
                                        year: "2-digit",
                                        month: "2-digit",
                                        day: "2-digit",
                                      })}
                                    </p>
                                  ) : columnName.value == "order_type" ? (
                                    <p>
                                      {orderTypes?.length > 0
                                        ? getOrderType(
                                            orderTypes,
                                            workOrder[columnName.value]
                                          ).chipLabel
                                        : "-"}
                                    </p>
                                  ) : (
                                    <p>
                                      {columnName.icon ? columnName.icon : null}
                                      {workOrder[columnName.value]}
                                    </p>
                                  )}
                                </>
                              ) : (
                                <p>{"-"}</p>
                              )}
                            </td>
                            {/* {columnName.type == "select" ? (
                              <>
                                {columnName.value == "order_type" ? (
                                  <td
                                    style={{
                                      minWidth: "100px !important",
                                      paddingBottom: "10px",
                                    }}
                                    className={globalClasses.tableTh}
                                  >
                                    
                                    <FileField
                                      placeHolder={columnName.label}
                                      type={columnName.type}
                                      name={`work_orders[${index}].${columnName.value}`}
                                      value={workOrder.order_type}
                                      inputLabel="ordertype"
                                      inputValue="orderID"
                                      options={orderTypes}
                                      setFieldValue={setFieldValue}
                                      disabled={true}
                                    />
                                  </td>
                                ) : columnName.value == "occupancy_status" ? (
                                  <td
                                    style={{
                                      minWidth: "100px !important",
                                      paddingBottom: "10px",
                                    }}
                                    className={globalClasses.tableTh}
                                  >
                                   
                                    <FileField
                                      placeHolder={columnName.label}
                                      type={columnName.type}
                                      name={`work_orders[${index}].${columnName.value}`}
                                      value={workOrder.occupancy_status}
                                      inputLabel="label"
                                      inputValue="value"
                                      options={occupancyDropdown}
                                      setFieldValue={setFieldValue}
                                      disabled={true}
                                    />
                                  </td>
                                ) : null}
                              </>
                            ) : (
                              <td className={globalClasses.tableTh}>
                                
                                <FileField
                                  placeHolder={columnName.label}
                                  type={columnName.type}
                                  name={`work_orders[${index}].${columnName.value}`}
                                  icon={columnName.icon}
                                  disabled={true}
                                />
                              </td>
                            )} */}
                          </>
                        ))}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>

            {/* <Pagination
           count={noOfPages}
           page={page}
           onChange={handleChange}
           defaultPage={1}
           color="primary"
           showFirstButton
           showLastButton
           classes={{ ul: classes.paginator }}
         /> */}
          </div>
        </div>
      )}
    />
  );
}
export default WorkOrderTable;
