// Function which returns a guuid
function generateQuickGuid(type) {
  if (type === "client") {
    return `${Math.floor(100000 + Math.random() * 900000)}`;
  } else if (type === "work_order") {
    return `${Math.floor(1000000 + Math.random() * 9000000)}`;
  } else if (type === "order") {
    return `${Math.floor(10000 + Math.random() * 90000)}`;
  } else {
    return `${Math.floor(10000000 + Math.random() * 90000000)}`;
  }
}

// Function which returned TaxID mask.
const getTaxIDMask = () => {
  return "99-9999999";
};

// Given array and ID, find object with id.
const getClient = (arr, id) => {
  var result = arr.find((x) => x.client_id === id);
  return result;
};

// Given array and ID, find object with id.
const getOrderType = (arr, id) => {
  var result = arr.find((x) => x.orderID === id);
  return result;
};

// Given String - get acronym.
const convertToAcronym = (str) => {
  const words = str.split(" ");
  let acronym = "";

  words.forEach((word) => {
    acronym += word.charAt(0).toUpperCase();
  });

  return acronym;
};

// Given work orders list - add selected boolean field to each row object
const addSelectedField = (file) => {
  // Adding Selected field to work_orders in file
  if (file?.work_orders?.length > 0) {
    var tempWorkData = file.work_orders.map((item) => ({
      ...item,
      selected: false,
    }));
    var fileTemp = {
      ...file,
      work_orders: tempWorkData,
    };
    return fileTemp;
  } else {
    return file;
  }
};

// Function to get dates in string format.
const getDates = () => {
  const list = [];
  for (let i = 1; i <= 28; i++) {
    if (i % 10 === 1 && i !== 11) {
      //st
      list.push({ value: i, label: `${i}st` });
    } else if (i % 10 === 2 && i !== 12) {
      // nd
      list.push({ value: i, label: `${i}nd` });
    } else if (i % 10 === 3 && i !== 13) {
      // rd
      list.push({ value: i, label: `${i}rd` });
    } else {
      // th
      list.push({ value: i, label: `${i}th` });
    }
  }
  return list;
};

const ContactInformationJson = {
  work_order: generateQuickGuid("work_order"),
  request_date: "2023-05-11",
  order_type: 3,
  date_completed: "",
  client_provided_first_name: "Usman",
  client_provided_middle_name: "",
  client_provided_last_name: "Mian",
  client_provided_suffix: "Mr.",
  corrected_first_name: "Usman",
  corrected_middle_name: "Khalid",
  corrected_last_name: "Mian",
  corrected_suffix: "Mr.",
  DOB: "10/10/2023",
  SSN: "333-424-3243",
  current_address: {
    street_address: "3943 Clair Blvd",
    street_address_2: "",
    city: "New York",
    state: "NY",
    zip: "32422",
  },
  current_address_2: {
    street_address: "",
    street_address_2: "",
    city: "",
    state: "",
    zip: "",
  },

  phone: {
    phone1: "342-342-5432",
    phone2: "342-325-5432",
    phone3: "342-342-5432",
    phone4: "342-342-5432",
  },
  email: {
    email1: "example1@gmail.com",
    email2: "example2@gmail.com",
    email3: "example3@gmail.com",
    email4: "example4@gmail.com",
  },
  occupancy_status: "Unknown",
  completed_date: "2023-05-11",
  invoice_date: null,
  invoice_number: null,
  invoice_status: "Pending",
  paid: null,
  amount: 35,
  check_date: null,
  check_no: null,
};

const OccupancyVerificationJson = {
  work_order: generateQuickGuid("work_order"),
  request_date: "2023-05-11",
  order_type: 1,
  date_completed: "",
  client_provided_first_name: "Usman",
  client_provided_middle_name: "",
  client_provided_last_name: "Mian",
  client_provided_suffix: "Mr.",
  corrected_first_name: "Usman",
  corrected_middle_name: "Khalid",
  corrected_last_name: "Mian",
  corrected_suffix: "Mr.",
  DOB: "10/10/2023",
  SSN: "333-424-3243",
  current_address: {
    street_address: "3943 Clair Blvd",
    street_address_2: "",
    city: "New York",
    state: "NY",
    zip: "32422",
  },
  current_address_2: {
    street_address: "",
    street_address_2: "",
    city: "",
    state: "",
    zip: "",
  },

  phone: {
    phone1: "342-342-5432",
    phone2: "342-325-5432",
    phone3: "342-342-5432",
    phone4: "342-342-5432",
  },
  email: {
    email1: "example1@gmail.com",
    email2: "example2@gmail.com",
    email3: "example3@gmail.com",
    email4: "example4@gmail.com",
  },
  occupancy_status: "Unknown",
  completed_date: "2023-05-11",
  invoice_date: null,
  invoice_number: null,
  invoice_status: "Pending",
  paid: null,
  amount: 35,
  check_date: null,
  check_no: null,
};
const IdVerificationJson = {
  work_order: generateQuickGuid("work_order"),
  request_date: "2023-05-11",
  order_type: 2,
  date_completed: "",
  client_provided_first_name: "Usman",
  client_provided_middle_name: "",
  client_provided_last_name: "Mian",
  client_provided_suffix: "Mr.",
  corrected_first_name: "Usman",
  corrected_middle_name: "Khalid",
  corrected_last_name: "Mian",
  corrected_suffix: "Mr.",
  DOB: "10/10/2023",
  SSN: "333-424-3243",
  current_address: {
    street_address: "",
    street_address_2: "",
    city: "",
    state: "",
    zip: "",
  },
  current_address_2: {
    street_address: "",
    street_address_2: "",
    city: "",
    state: "",
    zip: "",
  },

  phone: {
    phone1: "342-342-5432",
    phone2: "342-325-5432",
    phone3: "342-342-5432",
    phone4: "342-342-5432",
  },
  email: {
    email1: "example1@gmail.com",
    email2: "example2@gmail.com",
    email3: "example3@gmail.com",
    email4: "example4@gmail.com",
  },
  occupancy_status: "Unknown",
  completed_date: "2023-05-11",
  invoice_date: null,
  invoice_number: null,
  invoice_status: "Pending",
  paid: null,
  amount: 35,
  check_date: null,
  check_no: null,
};

export {
  getOrderType,
  generateQuickGuid,
  getTaxIDMask,
  getClient,
  convertToAcronym,
  getDates,
  addSelectedField,
  OccupancyVerificationJson,
  ContactInformationJson,
  IdVerificationJson,
};
