export const initialProductLine = {
  description: "",
  quantity: "",
  rate: "",
};
export const initialInvoice = {
  logo: "../images/QALogo.png",
  logoWidth: 200,
  title: "INVOICE",
  companyName: "QualityClaims",
  name: "",
  companyAddress: "",
  companyAddress2: "",
  companyCountry: "United States",
  billTo: "Bill To:",
  clientName: "",
  clientAddress: "",
  clientAddress2: "",
  clientCountry: "United States",
  invoiceTitleLabel: "Invoice#",
  invoiceTitle: "",
  invoiceDateLabel: "Invoice Date",
  invoiceDate: "",
  invoiceDueDateLabel: "Due Date",
  invoiceDueDate: "",
  serviceLineDescription: "Professional Services",
  serviceLineQuantity: "Qty",
  serviceLineQuantityRate: "Rate",
  serviceLineQuantityAmount: "Amount",
  serviceTotalLabel: "Services total",
  serviceLine: [],
  expenseLineDescription: "Expense Itemization",
  expenseLineQuantity: "Qty",
  expenseLineQuantityRate: "Rate",
  expenseLineQuantityAmount: "Amount",
  expenseTotalLabel: "Expense total",
  expenseLine: [],
  subTotalLabel: "Sub Total",
  taxLabel: "Sale Tax (10%)",
  totalLabel: "Total amount",
  currency: "$",
  notesLabel: "Notes",
  notes: "",
  termLabel: "Terms & Conditions",
  term: ".",
};

export const caseData = {
  case_detail: {
    user_id: "02173cf1-96e1-40c4-8760-a2ed6ea3c15d",
    created_at: "2022-10-13",
    flag: false,
    client: "McCarthy & Holthus",
    client_id: "638938",
    case_details: {
      assigment_information: {
        event: "Closed File Investigation Complete",
        supervisor_name: "429726",
        investigator_name: "ecattaneo",
      },
      notes: [],
      files: [],
      investigator_information: {
        invoice_received_date: "",
        investigator_id: "",
        due_date: "",
        hours_authorized: "0",
        rating: "0",
        invoice_approved_date: "",
        date_hired: "",
        database_billed: "0",
        hours_billed: "0",
        report_received_date: "",
        miles_billed: "0",
      },
      time: "26846740",
      budget_information: {
        scheduled: "0",
        used: "0",
      },
      Subjects_information: [
        {
          subject_id: "54471113",
          street_address: "5935 Shattuck Road Apt 103",
          deceased: false,
          gender: "",
          is_primary: true,
          city: "Saginaw",
          death_cert_received_date: "",
          mother_name: "",
          zip_code: "48603",
          ssn: "451-70-____",
          date_of_death: "",
          state: "Michigan",
          relationship: [
            {
              relationshipStatus: "",
              subjectId: "",
              subjectIndex: "-1",
            },
          ],
          first_name: "Irene",
          place_of_death: "",
          email: [""],
          last_name: "Sanchez",
          death_cert_order_date: "",
          middle_name: "E",
          marital_status: "",
          relationship_to_subject: "",
          father_name: "",
          phone: [""],
          dob: "",
          social_media: [
            {
              type: "facebook",
              value: "",
            },
            {
              type: "twitter",
              value: "",
            },
            {
              type: "instagram",
              value: "",
            },
            {
              type: "linkedin",
              value: "",
            },
            {
              type: "personal",
              value: "",
            },
          ],
        },
      ],
      tasks: [],
      case_information: {
        subject_first_name: "Irene",
        subject_property_address: "219 E Pyron Avenue, San Antonio, TX 78214",
        death_certificate_ordered: "",
        death_certificate_received: "",
        city: "Saginaw",
        case_info: "",
        due_date: "2022-11-12",
        referral_notes:
          "We have a surplus funds matter in which Irene E. Sanchez is a prior owner and entitled to claim funds. I’ve attached Westlaw results for Irene E. Sanchez. If you discover that she is deceased, please identify and locate all of her heirs. Please let the heirs know that they’ll need to provide a death certificate before we can provide them with any information. Let me know if you have any questions. Feel free to use a local investigator if needed. Please submit your final report and invoice in Adobe pdf format when you reply all to this email. ",
        case_type: "Surplus Funds",
        file_no: "",
        date_referred: "2022-10-13",
        follow_up: "",
        invoice_date: "2022-11-17",
        subject_last_name: "Sanchez",
        sale_date: "",
        create_at: "2022-10-13T22:19:57.142Z",
        file_due: false,
        final_report_send_date: "2022-11-17",
      },
      client_information: {
        phone_number: "(619) 243-3929",
        date_of_sale: "",
        client_file_no: "TX-22-81440-SF",
        client_loan: "",
        follow_up: "",
        reopened_date: "",
      },
    },
    status: "inactive",
    file_no: "2022MH20113",
    primary_subject: "54471113",
    by_status: true,
  },
  case_search_results: {
    searchResults: {
      54471113: {
        delvepoint: {},
        clear: {},
        fastpeople: {},
        tracers: {},
        tlo: {},
      },
    },
    file_no: "2022MH20113",
  },
};

export const clientData = [
  {
    search_count: {
      year_2022: "2",
      year_2023: "4",
    },
    local_investigator_mileage_rate: "0.65",
    client_id: "896410",
    contacts: [
      {
        last_name: "MacLaren",
        id: "587352",
        first_name: "Rebecca",
        phone: "(310) 341-2922",
        email: "maclaren@sawyerlabar.com",
      },
    ],
    zip_code: "90045",
    hourly_rate: "125",
    abbreviation: "SL",
    client_name: "Sawyer Labar",
    street_address: "6060 Center Dr. 10th Floor",
    state: "California",
    city: "Los Angeles",
    local_investigator_rate: "125",
    database_search_rate: "25",
  },
  {
    local_investigator_mileage_rate: "0.65",
    client_id: "950881",
    contacts: [
      {
        last_name: "Rodriguez",
        id: "786974",
        first_name: "Regina",
        phone: "(469) 804-8457",
        email: "RRodriguez3@alaw.net",
      },
      {
        last_name: "",
        id: "986616",
        first_name: "",
        phone: "",
        email: "",
      },
    ],
    zip_code: "72202",
    hourly_rate: "150",
    abbreviation: "ALAW",
    client_name: "Albertelli Law",
    street_address: "1 Information Way Suite 201",
    state: "Arkansas",
    city: "Little Rock",
    local_investigator_rate: "150",
    database_search_rate: "25",
  },
  {
    search_count: {
      year_2022: "7",
      year_2023: "1",
    },
    local_investigator_mileage_rate: "0.65",
    client_id: "972600",
    contacts: [
      {
        last_name: "Doe",
        id: "429726",
        first_name: "John",
        phone: "(039) 258-9203",
        email: "john@gmail.com",
      },
    ],
    zip_code: "49055",
    hourly_rate: "19",
    abbreviation: "ZZZZ",
    client_name: "Zoro",
    street_address: "08310 35TH ST1",
    state: "Michigan",
    city: "GOBLES",
    local_investigator_rate: "18",
    database_search_rate: "13",
  },
  {
    search_count: {
      year_2022: "195",
      year_2023: "76",
    },
    local_investigator_mileage_rate: "0.65",
    client_id: "638938",
    contacts: [
      {
        last_name: "Klam",
        id: "429726",
        first_name: "Kristina",
        phone: "(619) 243-3929",
        email: "kklam@mccarthyholthus.com",
      },
    ],
    zip_code: "92108",
    hourly_rate: "100",
    abbreviation: "MH",
    client_name: "McCarthy & Holthus",
    street_address: "2763 Camino del Rio S",
    state: "California",
    city: "San Diego",
    local_investigator_rate: "100",
    database_search_rate: "25",
  },
  {
    search_count: {
      year_2022: "1",
    },
    local_investigator_mileage_rate: "20",
    client_id: "294237",
    contacts: [
      {
        last_name: "Doe",
        id: "429726",
        first_name: "John",
        phone: "(039) 258-9203",
        email: "john@gmail.com",
      },
    ],
    hourly_rate: "19",
    abbreviation: "USB",
    client_name: "US Bank",
    street_address: "08310 35TH ST1",
    state: "Michigan",
    city: "Cincinnati",
    local_investigator_rate: "18",
    database_search_rate: "13",
  },
  {
    local_investigator_mileage_rate: "0.65",
    client_id: "811210",
    contacts: [
      {
        last_name: "Blancato",
        id: "709390",
        first_name: "Matthew",
        phone: "(619) 369-3323",
        email: "",
      },
    ],
    zip_code: "92108",
    hourly_rate: "100",
    abbreviation: "HRO",
    client_name: "Hiden, Rott & Oertle, LLP",
    street_address: "2635 Camino del Rio S Suite 110",
    state: "California",
    city: "San Diego",
    local_investigator_rate: "100",
    database_search_rate: "25",
  },
  {
    local_investigator_mileage_rate: "0.65",
    client_id: "553368",
    contacts: [
      {
        last_name: "Whalen",
        id: "868787",
        first_name: "Paula",
        phone: "",
        email: "pwhalen@whalenent.com",
      },
      {
        last_name: "",
        id: "111842",
        first_name: "",
        phone: "",
        email: "",
      },
    ],
    zip_code: "92109",
    hourly_rate: "150",
    abbreviation: "W Pack",
    client_name: "W Packaging",
    street_address: "864 Grand Ave. PMB 504",
    state: "California",
    city: "San Diego",
    local_investigator_rate: "150",
    database_search_rate: "25",
  },
];

export const InvoiceData = {
  file_no: "2022MH20113",
  database_search: {
    delvepoint: "0",
    fastpeople: "1",
    tracers: "0",
    tlo: "0",
    clear: "0",
  },
  time_spent: 26846740,
  client_rates: {
    Items: [
      {
        local_investigator_mileage_rate: "0.65",
        local_investigator_rate: "100",
        database_search_rate: "25",
        hourly_rate: "100",
      },
    ],
    Count: 1,
    ScannedCount: 1,
    ResponseMetadata: {
      RequestId: "191BLGL0FR6LQN7R5AR1F0IJCRVV4KQNSO5AEMVJF66Q9ASUAAJG",
      HTTPStatusCode: 200,
      HTTPHeaders: {
        server: "Server",
        date: "Thu, 20 Apr 2023 15:18:29 GMT",
        "content-type": "application/x-amz-json-1.0",
        "content-length": "185",
        connection: "keep-alive",
        "x-amzn-requestid":
          "191BLGL0FR6LQN7R5AR1F0IJCRVV4KQNSO5AEMVJF66Q9ASUAAJG",
        "x-amz-crc32": "163873026",
      },
      RetryAttempts: 0,
    },
  },
  investigator_miles_spent: 0,
  investigator_hours_spent: 0,
  investigator_database_used: 0,
};
