export function AUTH_ACCESS_TOKEN() {
  return localStorage.getItem("accessToken");
}

export function AUTH_ID_TOKEN() {
  return localStorage.getItem("idToken");
}

export function AUTH_REFRESH_TOKEN() {
  return localStorage.getItem("refreshToken");
}

export const USERS_PER_PAGE = 10;
export const USER = "user";
export const REFRESH_TOKEN = "refreshToken";
export const ID_TOKEN = "idToken";
export const ACCESS_TOKEN = "accessToken";
export const THEME = "theme";
export const ORDER_TYPE = "orderTypes";

export const baseURL = "https://hosting.occutrack.com/";
