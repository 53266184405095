import React from "react";
import { Input, makeStyles } from "@material-ui/core";
import { Field, ErrorMessage } from "formik";
import FormError from "../FormError";

import FMUInputField from "./FormIkMUFormComponent/FMUInputField";
import { Typography } from "@material-ui/core";
import Select from "react-select";

const useStyles = makeStyles((theme) => ({
  parentChild: {
    height: "50px",
    flex: "1",
    width: "100%",
    "&:nth-child(1)": {
      paddingRight: "5vh",
    },
    "&:nth-child(2)": {
      paddingLeft: "5vh",
    },
  },
  rowContainer: {
    display: "flex",
    marginTop: "8px",
  },
  labelContainer: {
    display: "flex",
    width: "50%",
    alignItems: "center",
    "& p": {
      fontSize: "0.9em",
      // // fontWeight:"bold",
      color: "black",
    },
  },
  fieldContainer: {
    width: "50%",
    textAlign: "end",
  },
  input: {
    width: "95%",

    "& .MuiInputBase-input": {
      textIndent: "10px",
      textTransform: "capitalize",
      textAlign: "end",
    },
    height: "40px",
    paddingLeft: "3px",
    borderBottom: "1px solid #BABABA",
  },
  emailInput: {
    width: "95%",
    "& .MuiInputBase-input": {
      textIndent: "10px",
      textAlign: "end",
    },
    height: "40px",
    paddingLeft: "3px",

    // borderRadius: "10px",
    // border: "1px solid #9dc8d2",
    // backgroundColor: "#f8f8f8",
    borderBottom: "1px solid #BABABA",
  },
  dateInput: {
    width: "95%",
    "& .MuiInputBase-input": {
      textIndent: "10px",
      textAlign: "end",
    },
    height: "40px",
    paddingLeft: "3px",
    borderBottom: "1px solid #BABABA",
  },
  error: {
    fontSize: "12px",
    color: "red",
    marginTop: "0.25rem",
    paddingRight: "2.5%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
}));

const customStyles = {
  textAlign: "end",
  control: (base, state) => ({
    ...base,
    borderRadius: null,
    // Overwrittes the different states of border
    borderColor: null,

    borderStyle: null,
    borderBottom: "1px solid #BABABA",
    // Removes weird border around container
    boxShadow: null,
  }),
};

function SelectField({
  label,
  inputValue,
  inputLabel,
  name,
  required,
  setFieldValue,
  options,
  values,
}) {
  const classes = useStyles();
  return (
    <div className={classes.parentChild}>
      <div className={classes.rowContainer}>
        <div className={classes.labelContainer}>
          {required ? (
            <Typography>
              {label}
              <span style={{ color: "red", fontWeight: 500 }}>*</span>
            </Typography>
          ) : (
            <Typography>{label}</Typography>
          )}
        </div>
        <div className={classes.fieldContainer}>
          <Select
            // value={{ label: values[name] }}
            value={
              options
                ? options.find(
                    (option) => option[inputValue] === values[inputValue]
                  )
                : ""
            }
            // value={values[name]}
            onChange={(e) => {
              console.log(name, e[inputValue]);
              setFieldValue(name, e[inputValue], false);
            }}
            options={options}
            styles={customStyles}
            getOptionLabel={(option) => option[inputLabel]}
            getOptionValue={(option) => option[inputValue]}
            components={{
              IndicatorSeparator: () => null,
              // DropdownIndicator: () => null,
            }}
            placeholder={"Select"}
            style={
              {
                // width: "95%",
                // border: "1px solid #9dc8d2",
                // height: "40px",
                // paddingLeft: "3px",
                // borderRadius: "10px",
                // backgroundColor: "#f8f8f8",
              }
            }
          />
        </div>
      </div>
      <div>
        <ErrorMessage
          name={name}
          component={FormError}
          className={classes.error}
        />
      </div>
    </div>
  );
}

export default SelectField;
