import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
import { Pagination } from "@material-ui/lab";
import globalStyles from "../../helpers/GlobalStyles";
import { addSelectedField } from "../../helpers/helper";
import { DASHBOARD, SEARCH } from "../../types/routeConstants";

const useStyles = makeStyles((theme) => ({}), { index: 1 });

const columnNames = [
  { label: "Client Loan #", value: "client_loan_no" },
  { label: "Client Name", value: "client_name" },
  { label: "Name", value: "name" },
  { label: "Address", value: "address" },
];

/**

A React functional component for rendering the Returned list of Files on Search

*/
function FileList({ fileList }) {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const navigate = useNavigate();

  const itemsPerPage = 10;
  const [page, setPage] = React.useState(1);
  // const [connectionId, setConncectionId]= useState(false);
  // const [noOfPages] = React.useState(
  //     Math.ceil(fileList?.length / itemsPerPage)
  // );
  //   var noOfPages = Math.ceil(fileList?.length / itemsPerPage);
  // console.log(fileList);
  // const floorFigure = (figure, decimals) => {
  //   if (!decimals) decimals = 2;
  //   const d = Math.pow(10, decimals);
  //   return (parseInt(figure * d) / d).toFixed(decimals);
  // };

  return (
    <>
      <table className={globalClasses.table} style={{ width: "90%" }}>
        <thead className={globalClasses.tableHead}>
          <tr className={globalClasses.clientthtr}>
            <th className={globalClasses.tableTh}>#</th>

            {columnNames.map((columnName) => (
              <th className={globalClasses.tableTh}>{columnName.label}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {fileList
            ?.slice((page - 1) * itemsPerPage, page * itemsPerPage)
            .map((file, index) => (
              <tr key={index}>
                <td className={globalClasses.tableTh}>{index + 1}</td>

                <td className={globalClasses.tableTd}>
                  {file?.client_loan_no}
                </td>
                <td className={globalClasses.tableTd}>{file?.client_name}</td>
                <td className={globalClasses.tableTd}>{file?.name}</td>
                <td
                  className={globalClasses.tableTd}
                  style={{ color: "#147CFF", cursor: "pointer" }}
                  onClick={() => {
                    navigate(`${DASHBOARD.path}/${file.id}`);
                  }}
                >
                  {`${file?.street_address}
                  ${file?.city}, ${file?.state}
                  ${file?.zip_code}`}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      {/* <Pagination
        count={noOfPages}
        page={page}
        onChange={handleChange}
        defaultPage={1}
        color="primary"
        showFirstButton
        showLastButton
        classes={{ ul: classes.paginator }}
      /> */}
    </>
  );
}
export default FileList;
