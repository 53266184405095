import React, { FC } from 'react'
import { Text } from '@react-pdf/renderer'
import compose from './styles/compose'


function EditableInput({ className, placeholder, value, onChange, pdfMode }){
  return (
    <>
      {pdfMode ? (
        <Text style={compose('invoice-span ' + (className ? className : ''))}>{value}</Text>
      ) : (
        <input
          type="text"
          className={'invoice-input ' + (className ? className : '')}
          placeholder={placeholder || ''}
          value={value || ''}
          onChange={onChange ? (e) => onChange(e.target.value) : undefined}
        />
      )}
    </>
  )
}

export default EditableInput
