import React, { FC, useState, useEffect } from "react";
import { Font } from "@react-pdf/renderer";
import { initialInvoice, initialProductLine } from "./data/initData";
import Document from "./Document";
import Page from "./Page";
import "./invoice.css";
import View from "./View";
import EditableInput from "./EditableInput";
import EditableSelect from "./EditableSelect";
import EditableCalendarInput from "./EditableCalenderInpu";
import format from "date-fns/format";
import Text from "./Text";
import EditableTextarea from "./EditableTextarea";
import DownloadPDF from "./DownloadPDF";
import Endpoint from "../../endpoint/index";
import { getClient } from "../../helpers/helper";
import EditableFileImage from "./EditableFileImage";

import { clientData, InvoiceData } from "./data/initData";

Font.register({
  family: "Nunito",
  fonts: [
    { src: "https://fonts.gstatic.com/s/nunito/v12/XRXV3I6Li01BKofINeaE.ttf" },
    {
      src: "https://fonts.gstatic.com/s/nunito/v12/XRXW3I6Li01BKofA6sKUYevN.ttf",
      fontWeight: 600,
    },
  ],
});

function InvoicePage({ data, pdfMode, caseData, check }) {
  console.log(caseData);
  const [invoice, setInvoice] = useState(
    data ? { ...data } : { ...initialInvoice }
  );
  const [serviceTotal, setServiceTotal] = useState();
  const [expenseTotal, setExpenseTotal] = useState();
  const [subTotal, setSubTotal] = useState();
  const [saleTax, setSaleTax] = useState();
  const invoiceDate =
    invoice.invoiceDate !== "" ? new Date(invoice.invoiceDate) : new Date();
  const invoiceDueDate =
    invoice.invoiceDueDate !== ""
      ? new Date(invoice.invoiceDueDate)
      : new Date(invoiceDate.valueOf());

  if (invoice.invoiceDueDate === "") {
    invoiceDueDate.setDate(invoiceDueDate.getDate() + 30);
  }

  useEffect(() => {
    // 2022dev20000
    // caseData.caseData.case_detail.file_no

    if (!check) {
      // Endpoint.getClients()
      //   .then((res) => {
      if (clientData.length !== 0) {
        let client = getClient(clientData, caseData.case_detail.client_id);
        // const userInfo = getUser();
        const newInvoice = { ...invoice };
        newInvoice["name"] = "test";
        newInvoice["companyAddress"] = "test-address";
        newInvoice["companyAddress2"] = "";
        newInvoice["clientName"] = client.client_name;
        newInvoice["clientAddress"] = client.street_address;
        newInvoice[
          "clientAddress2"
        ] = `${client.city} ${client.state} ${client.zip_code}`;
        var rate = client.hourly_rate;
        console.log(caseData);

        // Endpoint.getInvoice({
        //   file_no: caseData.caseData.case_detail.file_no,
        // })
        // .then((inv) => {
        const inv = { data: InvoiceData };
        const expenseLine = [...newInvoice.expenseLine];
        if (expenseLine.length === 0) {
          let totalSum = 0;
          for (let item in inv.data.database_search) {
            console.log(item);
            totalSum = totalSum + parseInt(inv.data.database_search[item]);
          }
          totalSum = totalSum.toString();
          expenseLine.push({
            description: "Database searches",
            quantity: totalSum,
            rate: inv.data.client_rates.Items[0].database_search_rate,
          });
          expenseLine.push({
            description: "Investigator database searches",
            quantity: inv.data.investigator_database_used,
            rate: inv.data.client_rates.Items[0].database_search_rate,
          });
          expenseLine.push({
            description: "Local investigator miles",
            quantity: inv.data.investigator_miles_spent,
            rate: inv.data.client_rates.Items[0]
              .local_investigator_mileage_rate,
          });
        }
        // productLines.push({description: 'Fastpeople',quantity: inv.data.fastpeople,rate: '0',})
        // productLines.push({description: 'Delvepoint',quantity: inv.data.delvepoint,rate: '0',})
        // productLines.push({description: 'Tlo',quantity: inv.data.tlo,rate: '0',})
        // productLines.push({description: 'Clear',quantity: inv.data.clear,rate: '0',})
        // productLines.push({description: 'Tracers',quantity: inv.data.tracers,rate: '0',})
        // productLines.push({description: 'Time spent on case',quantity: parseFloat(inv.data.time_spent/3.6e+6).toFixed(2),rate: rate,})
        const serviceLine = [...newInvoice.serviceLine];
        if (serviceLine.length === 0) {
          // serviceLine.push({description:"Investigation",quantity:parseFloat(caseData.caseData.case_detail.case_details.time/3.6e+6).toFixed(2),rate:'0'})
          serviceLine.push({
            description: "Hourly rate",
            quantity: parseFloat(inv.data.time_spent / 3.6e6).toFixed(2),
            rate: inv.data.client_rates.Items[0].hourly_rate,
          });
          serviceLine.push({
            description: "Local investigator rate",
            quantity: inv.data.investigator_hours_spent,
            rate: inv.data.client_rates.Items[0].local_investigator_rate,
          });
        }

        setInvoice({ ...newInvoice, serviceLine, expenseLine });
        // })
        // .catch((err) => {
        //   console.log(err);
        // });
        // setInvoice({...newInvoice,productLines})
        //       }
        //     })
        //     .catch((err) => {});
      }
    }
  }, []);

  const dateFormat = "MMM dd, yyyy";
  const handleChange = (name, value) => {
    if (name !== "productLines") {
      const newInvoice = { ...invoice };
      newInvoice[name] = value;
      // if (name === 'logoWidth' && typeof value === 'number') {
      //   newInvoice[name] = value
      // } else if (name !== 'logoWidth' && typeof value === 'string') {
      //   newInvoice[name] = value
      // }

      setInvoice(newInvoice);
    }
  };

  const handleProductLineChange = (type, index, name, value) => {
    if (type === "service") {
      const serviceLines = invoice.serviceLine.map((serviceLine, i) => {
        if (i === index) {
          const newServiceLine = { ...serviceLine };

          if (name === "description") {
            newServiceLine[name] = value;
          } else {
            if (
              value[value.length - 1] === "." ||
              (value[value.length - 1] === "0" && value.includes("."))
            ) {
              newServiceLine[name] = value;
            } else {
              const n = parseFloat(value);

              newServiceLine[name] = (n ? n : 0).toString();
            }
          }
          return newServiceLine;
        }

        return { ...serviceLine };
      });
      setInvoice({ ...invoice, serviceLine: [...serviceLines] });
    } else {
      const expenseLines = invoice.expenseLine.map((expenseLine, i) => {
        if (i === index) {
          const newExpenseLine = { ...expenseLine };

          if (name === "description") {
            newExpenseLine[name] = value;
          } else {
            if (
              value[value.length - 1] === "." ||
              (value[value.length - 1] === "0" && value.includes("."))
            ) {
              newExpenseLine[name] = value;
            } else {
              const n = parseFloat(value);

              newExpenseLine[name] = (n ? n : 0).toString();
            }
          }
          return newExpenseLine;
        }

        return { ...expenseLine };
      });
      console.log(expenseLines);
      setInvoice({ ...invoice, expenseLine: [...expenseLines] });
    }
  };
  const calculateAmount = (quantity, rate) => {
    const quantityNumber = parseFloat(quantity);
    const rateNumber = parseFloat(rate);
    const amount =
      quantityNumber && rateNumber ? quantityNumber * rateNumber : 0;

    return amount.toFixed(2);
  };
  const handleRemove = (type, i) => {
    if (type === "service") {
      const serviceLine = invoice.serviceLine.filter(
        (productLine, index) => index !== i
      );

      setInvoice({ ...invoice, serviceLine });
    } else {
      const expenseLine = invoice.expenseLine.filter(
        (productLine, index) => index !== i
      );

      setInvoice({ ...invoice, expenseLine });
    }
  };
  const handleAdd = (type) => {
    if (type === "service") {
      const serviceLine = [...invoice.serviceLine, { ...initialProductLine }];

      setInvoice({ ...invoice, serviceLine });
    } else {
      const expenseLine = [...invoice.expenseLine, { ...initialProductLine }];

      setInvoice({ ...invoice, expenseLine });
    }
  };
  useEffect(() => {
    let subTotal = 0;

    invoice.serviceLine.forEach((productLine) => {
      const quantityNumber = parseFloat(productLine.quantity);
      const rateNumber = parseFloat(productLine.rate);
      const amount =
        quantityNumber && rateNumber ? quantityNumber * rateNumber : 0;

      subTotal += amount;
    });

    setServiceTotal(subTotal);

    let subTotal2 = 0;

    invoice.expenseLine.forEach((productLine) => {
      const quantityNumber = parseFloat(productLine.quantity);
      const rateNumber = parseFloat(productLine.rate);
      const amount =
        quantityNumber && rateNumber ? quantityNumber * rateNumber : 0;

      subTotal2 += amount;
    });

    setExpenseTotal(subTotal2);

    setSubTotal(subTotal + subTotal2);
  }, [invoice.serviceLine, invoice.expenseLine]);
  return (
    <Document pdfMode={pdfMode}>
      <Page className="invoice-wrapper" pdfMode={pdfMode}>
        {!pdfMode && (
          <DownloadPDF data={invoice} caseData={caseData} check={true} />
        )}
        <View className="invoice-flex" pdfMode={pdfMode}>
          <View className="invoice-w-50 invoice-p-10" pdfMode={pdfMode}>
            <EditableFileImage
              className="invoice-logo"
              placeholder="Your Logo"
              value={invoice.logo}
              width={invoice.logoWidth}
              pdfMode={pdfMode}
              onChangeImage={(value) => handleChange("logo", value)}
              onChangeWidth={(value) => handleChange("logoWidth", value)}
            />
            <EditableInput
              className="fs-20 bold"
              placeholder="Your Company"
              value={invoice.companyName}
              onChange={(value) => handleChange("companyName", value)}
              pdfMode={pdfMode}
            />
            <EditableInput
              placeholder="Your Name"
              value={invoice.name}
              onChange={(value) => handleChange("name", value)}
              pdfMode={pdfMode}
            />
            <EditableInput
              placeholder="Company's Address"
              value={invoice.companyAddress}
              onChange={(value) => handleChange("companyAddress", value)}
              pdfMode={pdfMode}
            />
            {/* <EditableInput
              placeholder="City, State Zip"
              value={invoice.companyAddress2}
              onChange={(value) => handleChange('companyAddress2', value)}
              pdfMode={pdfMode}
            /> */}
            {/* <EditableSelect
              options={countryList}
              value={invoice.companyCountry}
              onChange={(value) => handleChange('companyCountry', value)}
              pdfMode={pdfMode}
            /> */}
          </View>
          <View className="invoice-w-50" pdfMode={pdfMode}>
            <EditableInput
              className="invoice-fs-45 invoice-right invoice-bold"
              placeholder="Invoice"
              value={invoice.title}
              onChange={(value) => handleChange("title", value)}
              pdfMode={pdfMode}
            />
          </View>
        </View>

        <View className="invoice-flex invoice-mt-40" pdfMode={pdfMode}>
          <View className="invoice-w-55" pdfMode={pdfMode}>
            <EditableInput
              className="invoice-bold invoice-dark invoice-mb-5"
              value={invoice.billTo}
              onChange={(value) => handleChange("billTo", value)}
              pdfMode={pdfMode}
            />
            <EditableInput
              placeholder="Your Client's Name"
              value={invoice.clientName}
              onChange={(value) => handleChange("clientName", value)}
              pdfMode={pdfMode}
            />
            <EditableInput
              placeholder="Client's Address"
              value={invoice.clientAddress}
              onChange={(value) => handleChange("clientAddress", value)}
              pdfMode={pdfMode}
            />
            <EditableInput
              placeholder="City, State Zip"
              value={invoice.clientAddress2}
              onChange={(value) => handleChange("clientAddress2", value)}
              pdfMode={pdfMode}
            />
          </View>

          <View className="invoice-w-45" pdfMode={pdfMode}>
            <View className="invoice-flex invoice-mb-5" pdfMode={pdfMode}>
              <View className="invoice-w-40" pdfMode={pdfMode}>
                <EditableInput
                  className="bold"
                  value={invoice.invoiceTitleLabel}
                  onChange={(value) => handleChange("invoiceTitleLabel", value)}
                  pdfMode={pdfMode}
                />
              </View>
              <View className="invoice-w-60" pdfMode={pdfMode}>
                <EditableInput
                  placeholder="INV-12"
                  value={invoice.invoiceTitle}
                  onChange={(value) => handleChange("invoiceTitle", value)}
                  pdfMode={pdfMode}
                />
              </View>
            </View>
            <View className="invoice-flex invoice-mb-5" pdfMode={pdfMode}>
              <View className="invoice-w-40" pdfMode={pdfMode}>
                <EditableInput
                  className="bold"
                  value={invoice.invoiceDateLabel}
                  onChange={(value) => handleChange("invoiceDateLabel", value)}
                  pdfMode={pdfMode}
                />
              </View>
              <View className="invoice-w-60" pdfMode={pdfMode}>
                <EditableCalendarInput
                  value={format(invoiceDate, dateFormat)}
                  selected={invoiceDate}
                  onChange={(date) =>
                    handleChange(
                      "invoiceDate",
                      date && !Array.isArray(date)
                        ? format(date, dateFormat)
                        : ""
                    )
                  }
                  pdfMode={pdfMode}
                />
              </View>
            </View>
            <View className="invoice-flex invoice-mb-5" pdfMode={pdfMode}>
              <View className="invoice-w-40" pdfMode={pdfMode}>
                <EditableInput
                  className="bold"
                  value={invoice.invoiceDueDateLabel}
                  onChange={(value) =>
                    handleChange("invoiceDueDateLabel", value)
                  }
                  pdfMode={pdfMode}
                />
              </View>
              <View className="invoice-w-60" pdfMode={pdfMode}>
                <EditableCalendarInput
                  value={format(invoiceDueDate, dateFormat)}
                  selected={invoiceDueDate}
                  onChange={(date) =>
                    handleChange(
                      "invoiceDueDate",
                      date && !Array.isArray(date)
                        ? format(date, dateFormat)
                        : ""
                    )
                  }
                  pdfMode={pdfMode}
                />
              </View>
            </View>
          </View>
        </View>

        {/* SERVICELINE */}
        <View
          className="invoice-mt-30 invoice-bg-dark invoice-flex"
          pdfMode={pdfMode}
        >
          <View className="invoice-w-48 invoice-p-4-8" pdfMode={pdfMode}>
            <EditableInput
              className="invoice-white invoice-bold"
              value={invoice.serviceLineDescription}
              onChange={(value) =>
                handleChange("serviceLineDescription", value)
              }
              pdfMode={pdfMode}
            />
          </View>
          <View className="invoice-w-17 invoice-p-4-8" pdfMode={pdfMode}>
            <EditableInput
              className="invoice-white invoice-bold invoice-right"
              value={invoice.serviceLineQuantity}
              onChange={(value) => handleChange("serviceLineQuantity", value)}
              pdfMode={pdfMode}
            />
          </View>
          <View className="invoice-w-17 invoice-p-4-8" pdfMode={pdfMode}>
            <EditableInput
              className="invoice-white invoice-bold invoice-right"
              value={invoice.serviceLineQuantityRate}
              onChange={(value) =>
                handleChange("serviceLineQuantityRate", value)
              }
              pdfMode={pdfMode}
            />
          </View>
          <View className="invoice-w-18 invoice-p-4-8" pdfMode={pdfMode}>
            <EditableInput
              className="invoice-white invoice-bold invoice-right invoice-white-width"
              value={invoice.serviceLineQuantityAmount}
              onChange={(value) =>
                handleChange("serviceLineQuantityAmount", value)
              }
              pdfMode={pdfMode}
            />
          </View>
        </View>

        {invoice.serviceLine.map((productLine, i) => {
          return pdfMode && productLine.description === "" ? (
            <Text key={i}></Text>
          ) : (
            <View
              key={i}
              className="invoice-row invoice-flex"
              pdfMode={pdfMode}
            >
              <View
                className="invoice-w-48 invoice-p-4-8 invoice-pb-10"
                pdfMode={pdfMode}
              >
                <EditableTextarea
                  className="invoice-dark"
                  rows={2}
                  placeholder="Enter item name/description"
                  value={productLine.description}
                  onChange={(value) =>
                    handleProductLineChange("service", i, "description", value)
                  }
                  pdfMode={pdfMode}
                />
              </View>
              <View
                className="invoice-w-17 invoice-p-4-8 invoice-pb-10"
                pdfMode={pdfMode}
              >
                <EditableInput
                  className="invoice-dark invoice-right"
                  value={productLine.quantity === "" ? 0 : productLine.quantity}
                  onChange={(value) =>
                    handleProductLineChange("service", i, "quantity", value)
                  }
                  pdfMode={pdfMode}
                />
              </View>
              <View
                className="invoice-w-17 invoice-p-4-8 invoice-pb-10"
                pdfMode={pdfMode}
              >
                <EditableInput
                  className="invoice-dark invoice-right"
                  value={productLine.rate === "" ? 0 : productLine.rate}
                  onChange={(value) =>
                    handleProductLineChange("service", i, "rate", value)
                  }
                  pdfMode={pdfMode}
                />
              </View>
              <View
                className="invoice-w-18 invoice-p-4-8 invoice-pb-10"
                pdfMode={pdfMode}
              >
                <Text className="invoice-dark invoice-right" pdfMode={pdfMode}>
                  {calculateAmount(productLine.quantity, productLine.rate)}
                </Text>
              </View>
              {!pdfMode && (
                <button
                  className="invoice-link invoice-row__remove"
                  aria-label="Remove Row"
                  title="Remove Row"
                  type="button"
                  onClick={() => handleRemove("service", i)}
                >
                  <span className="invoice-icon invoice-icon-remove invoice-bg-red"></span>
                </button>
              )}
            </View>
          );
        })}

        <View className="invoice-flex" pdfMode={pdfMode}>
          <View className="invoice-w-50 invoice-mt-10" pdfMode={pdfMode}>
            {!pdfMode && (
              <button
                className="invoice-link"
                type="button"
                onClick={(e) => {
                  handleAdd("service");
                }}
              >
                <span className="invoice-icon invoice-icon-add invoice-bg-green invoice-mr-10"></span>
                Add Line Item
              </button>
            )}
          </View>
          <View className="invoice-w-50 invoice-mt-20" pdfMode={pdfMode}>
            {/* <View className="invoice-flex" pdfMode={pdfMode}>
              <View className="invoice-w-50 invoice-p-5" pdfMode={pdfMode}>
                <EditableInput
                  value={invoice.subTotalLabel}
                  onChange={(value) => handleChange('subTotalLabel', value)}
                  pdfMode={pdfMode}
                />
              </View>
              <View className="invoice-w-50 invoice-p-5" pdfMode={pdfMode}>
                <Text className="invoice-right invoice-bold invoice-dark" pdfMode={pdfMode}>
                  {subTotal?.toFixed(2)}
                </Text>
              </View>
            </View> */}
            <View className="invoice-flex  invoice-p-5" pdfMode={pdfMode}>
              <View className="invoice-w-50 invoice-p-5" pdfMode={pdfMode}>
                <EditableInput
                  className="invoice-bold"
                  value={invoice.serviceTotalLabel}
                  onChange={(value) => handleChange("serviceTotalLabel", value)}
                  pdfMode={pdfMode}
                />
              </View>
              <View
                className="invoice-w-50 invoice-p-5 invoice-flex"
                pdfMode={pdfMode}
              >
                <EditableInput
                  className="invoice-dark invoice-bold invoice-right invoice-ml-30"
                  value={invoice.currency}
                  onChange={(value) => handleChange("currency", value)}
                  pdfMode={pdfMode}
                />
                <Text
                  className="invoice-right invoice-bold invoice-dark invoice-w-auto"
                  pdfMode={pdfMode}
                >
                  {serviceTotal?.toFixed(2)}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* EXPENSELINE */}

        <View
          className="invoice-mt-30 invoice-bg-dark invoice-flex"
          pdfMode={pdfMode}
        >
          <View className="invoice-w-48 invoice-p-4-8" pdfMode={pdfMode}>
            <EditableInput
              className="invoice-white invoice-bold"
              value={invoice.expenseLineDescription}
              onChange={(value) =>
                handleChange("expenseLineDescription", value)
              }
              pdfMode={pdfMode}
            />
          </View>
          <View className="invoice-w-17 invoice-p-4-8" pdfMode={pdfMode}>
            <EditableInput
              className="invoice-white invoice-bold invoice-right"
              value={invoice.expenseLineQuantity}
              onChange={(value) => handleChange("expenseLineQuantity", value)}
              pdfMode={pdfMode}
            />
          </View>
          <View className="invoice-w-17 invoice-p-4-8" pdfMode={pdfMode}>
            <EditableInput
              className="invoice-white invoice-bold invoice-right"
              value={invoice.expenseLineQuantityRate}
              onChange={(value) =>
                handleChange("expenseLineQuantityRate", value)
              }
              pdfMode={pdfMode}
            />
          </View>
          <View className="invoice-w-18 invoice-p-4-8" pdfMode={pdfMode}>
            <EditableInput
              className="invoice-white invoice-bold invoice-right invoice-white-width"
              value={invoice.expenseLineQuantityAmount}
              onChange={(value) =>
                handleChange("expenseLineQuantityAmount", value)
              }
              pdfMode={pdfMode}
            />
          </View>
        </View>

        {invoice.expenseLine.map((productLine, i) => {
          return pdfMode && productLine.description === "" ? (
            <Text key={i}></Text>
          ) : (
            <View
              key={i}
              className="invoice-row invoice-flex"
              pdfMode={pdfMode}
            >
              <View
                className="invoice-w-48 invoice-p-4-8 invoice-pb-10"
                pdfMode={pdfMode}
              >
                <EditableTextarea
                  className="invoice-dark"
                  rows={2}
                  placeholder="Enter item name/description"
                  value={productLine.description}
                  onChange={(value) =>
                    handleProductLineChange("expense", i, "description", value)
                  }
                  pdfMode={pdfMode}
                />
              </View>
              <View
                className="invoice-w-17 invoice-p-4-8 invoice-pb-10"
                pdfMode={pdfMode}
              >
                <EditableInput
                  className="invoice-dark invoice-right"
                  value={productLine.quantity === "" ? 0 : productLine.quantity}
                  onChange={(value) =>
                    handleProductLineChange("expense", i, "quantity", value)
                  }
                  pdfMode={pdfMode}
                />
              </View>
              <View
                className="invoice-w-17 invoice-p-4-8 invoice-pb-10"
                pdfMode={pdfMode}
              >
                <EditableInput
                  className="invoice-dark invoice-right"
                  value={productLine.rate === "" ? 0 : productLine.rate}
                  onChange={(value) =>
                    handleProductLineChange("expense", i, "rate", value)
                  }
                  pdfMode={pdfMode}
                />
              </View>
              <View
                className="invoice-w-18 invoice-p-4-8 invoice-pb-10"
                pdfMode={pdfMode}
              >
                <Text className="invoice-dark invoice-right" pdfMode={pdfMode}>
                  {calculateAmount(productLine.quantity, productLine.rate)}
                </Text>
              </View>
              {!pdfMode && (
                <button
                  className="invoice-link invoice-row__remove"
                  aria-label="Remove Row"
                  title="Remove Row"
                  type="button"
                  onClick={() => handleRemove("expense", i)}
                >
                  <span className="invoice-icon invoice-icon-remove invoice-bg-red"></span>
                </button>
              )}
            </View>
          );
        })}

        <View className="invoice-flex" pdfMode={pdfMode}>
          <View className="invoice-w-50 invoice-mt-10" pdfMode={pdfMode}>
            {!pdfMode && (
              <button
                className="invoice-link"
                type="button"
                onClick={(e) => {
                  handleAdd("expense");
                }}
              >
                <span className="invoice-icon invoice-icon-add invoice-bg-green invoice-mr-10"></span>
                Add Line Item
              </button>
            )}
          </View>
          <View className="invoice-w-50 invoice-mt-20" pdfMode={pdfMode}>
            {/* <View className="invoice-flex" pdfMode={pdfMode}>
              <View className="invoice-w-50 invoice-p-5" pdfMode={pdfMode}>
                <EditableInput
                  value={invoice.subTotalLabel}
                  onChange={(value) => handleChange('subTotalLabel', value)}
                  pdfMode={pdfMode}
                />
              </View>
              <View className="invoice-w-50 invoice-p-5" pdfMode={pdfMode}>
                <Text className="invoice-right invoice-bold invoice-dark" pdfMode={pdfMode}>
                  {subTotal?.toFixed(2)}
                </Text>
              </View>
            </View> */}
            <View className="invoice-flex " pdfMode={pdfMode}>
              <View className="invoice-w-50 " pdfMode={pdfMode}>
                <EditableInput
                  className="invoice-bold"
                  value={invoice.expenseTotalLabel}
                  onChange={(value) => handleChange("expenseTotalLabel", value)}
                  pdfMode={pdfMode}
                />
              </View>
              <View className="invoice-w-50  invoice-flex" pdfMode={pdfMode}>
                <EditableInput
                  className="invoice-dark invoice-bold invoice-right invoice-ml-30"
                  value={invoice.currency}
                  onChange={(value) => handleChange("currency", value)}
                  pdfMode={pdfMode}
                />
                <Text
                  className="invoice-right invoice-bold invoice-dark invoice-w-auto"
                  pdfMode={pdfMode}
                >
                  {expenseTotal?.toFixed(2)}
                </Text>
              </View>
            </View>

            <View
              className="invoice-flex invoice-bg-gray invoice-p-top invoice-p-left"
              pdfMode={pdfMode}
            >
              <View className="invoice-w-50 " pdfMode={pdfMode}>
                <EditableInput
                  className="invoice-bold"
                  value={"Professional Services"}
                  // onChange={(value) => handleChange('totalLabel', value)}
                  pdfMode={pdfMode}
                />
              </View>
              <View className="invoice-w-50  invoice-flex" pdfMode={pdfMode}>
                <EditableInput
                  className="invoice-dark invoice-bold invoice-right invoice-ml-30"
                  value={invoice.currency}
                  onChange={(value) => handleChange("currency", value)}
                  pdfMode={pdfMode}
                />
                <Text
                  className="invoice-right invoice-bold invoice-dark invoice-w-auto"
                  pdfMode={pdfMode}
                >
                  {serviceTotal?.toFixed(2)}
                </Text>
              </View>
            </View>

            <View
              className="invoice-flex invoice-bg-gray invoice-p-left invoice-b-bottom"
              pdfMode={pdfMode}
            >
              <View className="invoice-w-50 " pdfMode={pdfMode}>
                <EditableInput
                  className="invoice-bold"
                  value={"Expense Itemization"}
                  pdfMode={pdfMode}
                />
              </View>
              <View className="invoice-w-50 invoice-flex" pdfMode={pdfMode}>
                <EditableInput
                  className="invoice-dark invoice-bold invoice-right invoice-ml-30"
                  value={invoice.currency}
                  onChange={(value) => handleChange("currency", value)}
                  pdfMode={pdfMode}
                />
                <Text
                  className="invoice-right invoice-bold invoice-dark invoice-w-auto"
                  pdfMode={pdfMode}
                >
                  {expenseTotal?.toFixed(2)}
                </Text>
              </View>
            </View>

            <View
              className="invoice-flex invoice-bg-gray invoice-p-left"
              pdfMode={pdfMode}
            >
              <View className="invoice-w-50 " pdfMode={pdfMode}>
                <EditableInput
                  className="invoice-bold"
                  value={invoice.totalLabel}
                  onChange={(value) => handleChange("totalLabel", value)}
                  pdfMode={pdfMode}
                />
              </View>
              <View className="invoice-w-50  invoice-flex" pdfMode={pdfMode}>
                <EditableInput
                  className="invoice-dark invoice-bold invoice-right invoice-ml-30"
                  value={invoice.currency}
                  onChange={(value) => handleChange("currency", value)}
                  pdfMode={pdfMode}
                />
                <Text
                  className="invoice-right invoice-bold invoice-dark invoice-w-auto"
                  pdfMode={pdfMode}
                >
                  {subTotal?.toFixed(2)}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View className="invoice-mt-20" pdfMode={pdfMode}>
          <EditableInput
            className="invoice-bold invoice-w-100"
            value={invoice.notesLabel}
            onChange={(value) => handleChange("notesLabel", value)}
            pdfMode={pdfMode}
          />
          <EditableTextarea
            className="invoice-w-100"
            rows={2}
            value={invoice.notes}
            onChange={(value) => handleChange("notes", value)}
            pdfMode={pdfMode}
          />
        </View>
        {/* <View className="invoice-mt-20" pdfMode={pdfMode}>
          <EditableInput
            className="invoice-bold invoice-w-100"
            value={invoice.termLabel}
            onChange={(value) => handleChange('termLabel', value)}
            pdfMode={pdfMode}
          />
          <EditableTextarea
            className="invoice-w-100"
            rows={2}
            value={invoice.term}
            onChange={(value) => handleChange('term', value)}
            pdfMode={pdfMode}
          />
        </View> */}
      </Page>
    </Document>
  );
}

export default InvoicePage;
