import React, { useContext, useEffect, useState } from "react";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  LinearProgress,
  InputBase,
  Button,
} from "@material-ui/core";
import Select from "react-select";
import { states } from "../helpers/states";
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { Form, Formik, useFormikContext, Field, ErrorMessage } from "formik";
import { object, string } from "yup";
import * as Yup from "yup";
import moment from "moment";
import Swal from "sweetalert2";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import UploadIcon from "../icons/Upload";
import CloseIcon from "@material-ui/icons/Close";
import Endpoint from "../endpoint";
import { alertError } from "../helpers";
import Link from "@material-ui/core/Link";
import { Tooltip } from "@material-ui/core";
import RefreshIcon from "../icons/Refresh";
import globalStyles from "../helpers/GlobalStyles";
import stateListObj from "../helpers/allStates.json";
// import PredictionTable from "../components/PredictionTable";
import InputField from "../components/shared/InputField";
import SelectField from "../components/shared/SelectField";
import Loading from "../pages/Loading";
// import { orderTypes } from "../helpers/orderTypes";
import SearchFilterField from "../components/shared/SelectFilterField";
import { AuthContext } from "../auth/AuthWrapper";
const useStyles = makeStyles(
  (theme) => ({
    dialogTitle: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
      marginRight: "10px",
      padding: 0,
    },
    closeButton: {
      padding: "5px",
    },
    title: {
      fontSize: "20px",
      fontWeight: 600,
      color: "#0D8F75",
      textAlign: "center",
      padding: "10px",
    },
    body: {
      fontSize: "20px",
      fontWeight: 500,
      color: "#0D8F75",
      textAlign: "center",
      padding: "10px",
    },
    dialogRoot: {
      minWidth: "400px",
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: "35px",
    },
    loading: {
      marginTop: "15px",
    },
    downloadContent: {
      display: "flex",
      alignItems: "center",
      cursor: "pointer",
    },
    actionButton: {
      width: "34px",
      height: "34px",
      padding: 0,
      marginLeft: "14px",
      backgroundColor: "#0D8F75",
      borderRadius: "50%",
      "&:hover": {
        backgroundColor: "#0D8F75",
      },
      "& .MuiSvgIcon-root": {
        width: "20px",
        height: "20px",
      },
    },
    downloadIcon: {
      width: "20px",
      height: "20px",
      padding: "6px",
      borderRadius: "50%",
      backgroundColor: "#0D8F75",
      transform: "rotate(180deg)",
    },
    filterFields: {
      width: "100%",
      // margin: "auto",
      marginTop: "10px",
      "& label": {
        fontSize: "12px",
        color: "black",
        marginTop: "10px",
      },
      justifyContent: "space-around",
    },
    flexRow: {
      flexDirection: "row",
      display: "flex",
    },
    filterDivider: {
      margin: 0,
      color: "#808080",
      height: "145px",
      alignSelf: "center",
    },
    dateInput: {
      color: "green",
    },
    parent: {
      justifyContent: "space-between",
      flexDirection: "row",
      flexWrap: "wrap",
      display: "flex",
      paddingRight: "2%",
      marginTop: "2vh",
      minWidth: "450px",
    },
    parentChild: {
      flex: "1",
      width: "40%",
      "&:nth-child(1)": {
        paddingRight: "5vh",
      },
      "&:nth-child(2)": {
        paddingLeft: "5vh",
      },
    },
    rowContainer: {
      display: "flex",
      marginTop: "8px",
    },
    labelContainer: {
      display: "flex",
      width: "50%",
      alignItems: "center",
      "& p": {
        fontSize: "0.9em",
        // // fontWeight:"bold",
        color: "black",
      },
    },
    fieldContainer: {
      width: "50%",
      textAlign: "end",
    },
    button: {
      width: "160px",
      height: "30px",
      fontSize: "16px",
      marginTop: "12px",
      textTransform: "none",
    },
    postButton: {
      backgroundColor: theme.palette.primary.main,
      // fontFamily: "poppins",
      marginTop: "12px",
      fontSize: "0.9em",
      color: "#ffffff",
      textTransform: "inherit",
      // borderRadius: "5px",
      boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
      textAlign: "center",

      marginRight: "2%",
      border: "1px solid #3C72D9",
      height: "40px",

      width: "160px",

      // border: "1px solid #3C72D9",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: "0.8",
      },
      "&.Mui-disabled": {
        backgroundColor: "#e7e7e7",
        color: "white",
      },
    },
  }),
  { index: 1 }
);

const populatedDropdown = [
  { label: "None", value: "" },
  { label: "Populated", value: "Populated" },
  { label: "Not-populated", value: "Not-populated" },
  { label: "Both", value: "Both" },
];

const occupancyDropdown = [
  { label: "None", value: "" },
  { label: "Unknown", value: "Unknown" },
  { label: "Vacant", value: "Vacant" },
  { label: "Occupied", value: "Occupied" },
];

function InvoiceFilterForm(props) {
  const classes = useStyles();
  const globalClasses = globalStyles();
  const {
    clientName,
    invoiceListSearch,
    placeholder,
    inputValue,
    inputLabel,
    options,
    selectedColumns,
    setSelectedColumns,
  } = props;
  const [isLoading, setLoading] = useState(true);
  const [isRefreshing, setRefreshing] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const [clientList, setClientList] = useState([]);
  // const [orderTypes, setOrderTypes] = useState([]);

  const { orderTypes } = useContext(AuthContext);
  const preventDefault = (event) => event.preventDefault();

  const getClients = () => {
    Endpoint.Clients.getClients()
      .then((res) => {
        setClientList(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getOrderTypes = () => {
  //   Endpoint.Clients.getOrderType()
  //     .then((res) => {
  //       setOrderTypes(res.data);
  //       // setLoading(false);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // };

  useEffect(() => {
    getClients();
    // getOrderTypes();
    setLoading(false);
  }, []);

  const handleSubmit = (values, { setSubmitting }) => {
    if (values.state == "") {
      values.state = null;
    }
    // console.log(values);
    invoiceListSearch();
    return;
    // Endpoint.Users.getData(values)
    //   .then((res) => {
    //     console.log(values);
    //     Swal.fire({
    //       customClass: {
    //         container: classes.swal,
    //       },
    //       title: "Success!",
    //       html: "Query sent",
    //       icon: "success",
    //     }).then(() => {
    //       // console.log(res.data)
    //       // var data = Papa.parse(res.data, {header:true})
    //       invoiceListSearch(res.data.response, res.data.filteredList);
    //       setSubmitting(false);
    //     });
    //   })
    //   .catch((err) => {
    //     alertError("something went wrong", err);
    //     setSubmitting(false);
    //   });
  };
  const currentDate = new Date();
  return (
    <Formik
      enableReinitialize
      initialValues={{
        client: "",
        orderCompletedDate: "",
        loanNumber: "",
        invoiceDate: "",
        checkNumber: "",
        invoiceNumber: "",
        batchNum: "",
        state: "",

        orderType: "",
        orderCompletedDateRange: { start: "", end: "" },
        invoicePaid: "",
        workOrder: "",
        occupancyStatus: "",

        accuracy: "",
        template: "",
      }}
      validationSchema={object().shape({
        state: Yup.string().when(["city"], {
          is: (value) => value && value.length > 0,
          then: Yup.string().required("required."),
          otherwise: Yup.string().nullable(),
        }),
        city: Yup.string().nullable(),
        zipCode: Yup.number().nullable(),
        loanNumber: Yup.string().nullable(),
      })}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({
        values,
        isSubmitting,
        handleChange,
        handleBlur,
        setFieldValue,
        errors,
      }) => (
        <Form className={`${classes}`}>
          {isLoading ? (
            <Loading />
          ) : (
            <>
              <div
                className={`${classes.filterFields} ${globalClasses.flexRow}`}
              >
                <span className={classes.dialogContent}>
                  <div className={classes.parent}>
                    <SelectField
                      setFieldValue={setFieldValue}
                      values={values}
                      options={clientList}
                      inputValue={"client_id"}
                      inputLabel={"client_name"}
                      label={"Client"}
                      name={"client"}
                    />
                  </div>
                  <div className={classes.parent}>
                    <SelectField
                      setFieldValue={setFieldValue}
                      values={values}
                      options={populatedDropdown}
                      inputValue={"value"}
                      inputLabel={"label"}
                      label={"Order Completed Date"}
                      name={"orderCompletedDate"}
                    />
                  </div>
                  <div className={classes.parent}>
                    <InputField
                      label={"Loan Number"}
                      type={"number"}
                      name={"loanNumber"}
                    />
                  </div>
                  <div className={classes.parent}>
                    <SelectField
                      setFieldValue={setFieldValue}
                      values={values}
                      options={populatedDropdown}
                      inputValue={"value"}
                      inputLabel={"label"}
                      label={"Invoice Date"}
                      name={"invoiceDate"}
                    />
                  </div>
                  <div className={classes.parent}>
                    <InputField
                      label={"Check Number"}
                      type={"text"}
                      name={"checkNumber"}
                    />
                  </div>
                  <div className={classes.parent}>
                    <InputField
                      label={"Invoice Number"}
                      type={"number"}
                      name={"invoiceNumber"}
                    />
                  </div>
                  {/* <div className={classes.parent}>
                    <SelectField
                      setFieldValue={setFieldValue}
                      values={values}
                      options={states}
                      inputValue={"value"}
                      inputLabel={"label"}
                      label={"State"}
                      name={"state"}
                    />
                  </div> */}
                </span>
                <span className={classes.dialogContent}>
                  <div className={classes.parent}>
                    <SelectField
                      setFieldValue={setFieldValue}
                      values={values}
                      options={orderTypes}
                      inputValue={"orderID"}
                      inputLabel={"ordertype"}
                      label={"Order Type"}
                      name={"orderType"}
                    />
                  </div>
                  <div className={classes.parent}>
                    <SelectField
                      setFieldValue={setFieldValue}
                      values={values}
                      options={populatedDropdown}
                      inputValue={"value"}
                      inputLabel={"label"}
                      label={"Invoice Paid"}
                      name={"invoicePaid"}
                    />
                  </div>
                  <div className={classes.parent}>
                    <InputField
                      label={"Order Completed Start"}
                      type={"date"}
                      name={"orderCompletedDateRange.start"}
                    />
                  </div>
                  <div className={classes.parent}>
                    <InputField
                      label={"Order Completed End"}
                      type={"date"}
                      name={"orderCompletedDateRange.end"}
                    />
                  </div>
                  <div className={classes.parent}>
                    <InputField
                      label={"Work Order"}
                      type={"number"}
                      name={"workOrder"}
                    />
                  </div>
                  <div className={classes.parent}>
                    <SelectField
                      setFieldValue={setFieldValue}
                      values={values}
                      options={occupancyDropdown}
                      inputValue={"value"}
                      inputLabel={"label"}
                      label={"Occupancy Status"}
                      name={"occupancyStatus"}
                    />
                  </div>
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  marginLeft: "7%",
                  alignItems: "center",
                }}
              >
                <Button
                  type="submit"
                  size="large"
                  variant="contained"
                  color="primary"
                  disabled={isSubmitting}
                  className={`${classes.postButton}`}
                >
                  Search Invoices
                  {isSubmitting && (
                    <LinearProgress
                      color="primary"
                      className={globalClasses.linearProgress}
                    />
                  )}
                </Button>
                <SearchFilterField
                  placeholder={placeholder}
                  inputValue={"value"}
                  inputLabel={"label"}
                  options={options}
                  selectedColumns={selectedColumns}
                  setSelectedColumns={setSelectedColumns}
                />
              </div>
            </>
          )}
        </Form>
      )}
    </Formik>
  );
}

const FormDatePicker = (props) => {
  const classes = useStyles();
  return (
    <Field name="startDate">
      {({ field, meta, form: { setFieldValue } }) => {
        return (
          <DatePicker
            {...field}
            helperText={""}
            variant="inline"
            format="yyyy-MM-dd"
            InputProps={
              {
                // disableUnderline: true
              }
            }
            onChange={(val) => {
              setFieldValue(field.name, val);
            }}
          />
        );
      }}
    </Field>
  );
};

const FormDatePickerEndDate = (props) => {
  const classes = useStyles();
  return (
    <Field name="endDate">
      {({ field, meta, form: { setFieldValue } }) => {
        return (
          <DatePicker
            {...field}
            helperText={""}
            format="yyyy-MM-dd"
            InputProps={
              {
                // disableUnderline: true
              }
            }
            onChange={(val) => {
              setFieldValue(field.name, val);
            }}
          />
        );
      }}
    </Field>
  );
};

export default InvoiceFilterForm;
