import { Button, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import globalStyles from "../../helpers/GlobalStyles";
import Loading from "../Loading";
import ClientList from "./ClientList";
import ViewClient from "./ViewClient";
import AddClient from "./AddClient";
import Endpoint from "../../endpoint";
import { CLIENT } from "../../types/routeConstants";

const useStyle = makeStyles((theme) => ({
  container: {
    paddingTop: "calc(5vh + 60px)",
    paddingLeft: "2%",
    paddingRight: "2%",
  },
  caseContainer: {
    // width: "928px",
    // paddingTop: "calc(3vh + 50px)",
    width: "100%",
    // paddingLeft: "2%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },

  buttonRow: {
    display: "flex",
    flexDirection: "row",
    columnGap: "15px",
    // marginTop: "7vh",
    justifyContent: "space-between",
    // paddingLeft: "2%",
    // paddingRight: "2%",
    textAlign: "right",
  },
  clientTabs: {
    display: "flex",
    flexDirection: "row",
    columnGap: "15px",
    // marginTop: "7vh",
    // paddingLeft: "2%",
    // paddingRight: "2%",
    textAlign: "right",
  },
  clientelleTitle: {
    padding: "0 10px 0px 10px",
    alignItems: "center",
    display: "flex",
    color: "white",
    boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
    backgroundColor: "#4975FC",
    borderRadius: "20px",
    textTransform: "capitalize",
  },
  addClientButton: {
    boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
    backgroundColor: "#f8f8f8",
    // borderRadius: "5px",
    textTransform: "capitalize",
  },

  clientTable: {
    width: "100%",
    textAlign: "left",
    // color: "#4e4e4e",
    borderCollapse: "separate",
    borderSpacing: "0 15px",
    fontSize: "0.9em",
    // maxWidth:"fit-content",
    // overflow: "visible",
    // display: "block",
    // overflowX: "auto",
    whiteSpace: "nowrap",
  },
}));

const initialState = {
  client_id: "",
  client_name: "",
  street_address: "",
  city: "",
  zip_code: "",
  state: "",
  attention: "",
  tax_id: "",
  start_date: "",
  end_date: "",
  fee_lines: [],
};

const initialClientList = [
  {
    client_id: "23124",
    client_name: "Alex",
    street_address: "504 Street 3",
    city: "Lahore",
    zip_code: "2424",
    state: "MD",
    attention: "yes",
    tax_id: "",
    start_date: "2023-03-10",
    end_date: "",
    fee_lines: [
      {
        order_type_id: "30392",
        amount: "20",
        start_date: "2023-03-20",
        end_date: "",
      },
    ],
  },
  {
    client_id: "23120",
    client_name: "John Doe",
    street_address: "504 Street 3",
    city: "Lahore",
    zip_code: "2424",
    state: "MD",
    attention: "no",
    tax_id: "",
    start_date: "2023-03-10",
    end_date: "",
  },
];

/**

A React functional component for rendering the list of clients.
It provides functionality for displaying a list of clients

*/

function Client() {
  const classes = useStyle();
  const globalClasses = globalStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [view, SetView] = useState(true);
  const [refresh, setRefresh] = useState(true);
  const [tab, setTab] = useState(""); // client || order || fee

  const [initState, setInitState] = useState(initialState);

  const [clientList, setClientList] = useState(initialClientList);
  //   const [loading, setLoading] = useState(true);

  return (
    <>
      <div className={`${globalClasses.root}`}>
        <div className={`${globalClasses.flexColumn}`}>
          <div className={classes.container}>
            <br />
            <>
              <div
                className={classes.buttonRow}
                // style={{ textAlign: "right", paddingRight: "2%" }}
              >
                <span className={classes.clientTabs}>
                  {/* <span
                    className={classes.clientelleTitle}
                    // style={
                    //   tab == "client"
                    //     ? { backgroundColor: "#4e4e4e", color: "white" }
                    //     : null
                    // }
                    onClick={(e) => {
                      SetView(true);
                      setTab("client");
                    }}
                  >
                    Clientelle
                  </span> */}
                </span>
                <Button
                  className={classes.addClientButton}
                  style={
                    !view
                      ? { backgroundColor: "#4e4e4e", color: "white" }
                      : null
                  }
                  onClick={(e) => {
                    navigate(CLIENT.childPathAdd);
                  }}
                >
                  Add {tab}
                </Button>
              </div>
            </>
            <div className={classes.caseContainer}>
              <ClientList />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Client;
