import React, { useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton } from "@material-ui/core";
import { generateQuickGuid } from "../../helpers/helper";

const useStyles = makeStyles(
  (theme) => ({
    dialogTitle: {
      display: "flex",
      justifyContent: "flex-end",
      marginTop: "10px",
      marginRight: "10px",
      padding: 0,
    },
    closeButton: {
      padding: "5px",
    },
    title: {
      fontSize: "20px",
      fontWeight: 600,
      // color: '#0D8F75',
      //   color: "#147CFF",
      textAlign: "center",
      padding: "5px",
    },
    body: {
      fontSize: "1em",
      marginBottom: "0px !important",
      textAlign: "center",
    },
    dialogRoot: {
      minWidth: "500px",
    },
    dialogContent: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      paddingBottom: "35px",
    },
    loading: {
      marginTop: "15px",
    },
    dialogButtonRow: {},
    postButtonYes: {
      backgroundColor: theme.palette.primary.main,
      fontSize: ".8em",
      color: "#ffffff",
      textTransform: "inherit",
      boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
      textAlign: "center",
      marginRight: "2%",
      border: "1px solid #3C72D9",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: "0.8",
      },
      "&.Mui-disabled": {
        backgroundColor: "#e7e7e7",
        color: "white",
      },
    },
    postButtonNo: {
      backgroundColor: theme.palette.primary.main,
      fontSize: ".8em",
      color: "#ffffff",
      textTransform: "inherit",
      boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
      textAlign: "center",
      marginLeft: "2%",
      border: "1px solid #3C72D9",
      "&:hover": {
        backgroundColor: theme.palette.primary.main,
        opacity: "0.8",
      },
      "&.Mui-disabled": {
        backgroundColor: "#e7e7e7",
        color: "white",
      },
    },
  }),
  { index: 1 }
);

/**

A React functional component for rendering the New Work Order Dialog
When user attempts to create a new work order request for a file, this dialog pops up as a warning.

*/
const NewOrderDialog = ({
  showNewOrderDialog,
  handleCloseNewOrderDialog,
  arrayHelpers,
  selectedOrderType,
  workOrderJson,
}) => {
  const classes = useStyles();
  return (
    <Dialog
      open={showNewOrderDialog}
      classes={{ paper: classes.dialogRoot }}
      disableScrollLock={true}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleCloseNewOrderDialog();
        }
      }}
    >
      <DialogTitle className={classes.dialogTitle} disableTypography>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleCloseNewOrderDialog}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <DialogContentText className={classes.title} variant="h6">
          Create New Work Order
        </DialogContentText>
        <DialogContentText className={classes.body} variant="h6">
          {`This will create a request for a new order.`}
        </DialogContentText>
        <DialogContentText className={classes.body} variant="h6">
          {`Are you sure you want
          to proceed?`}
        </DialogContentText>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: "5%",
          }}
        >
          <Button
            className={classes.postButtonYes}
            onClick={() => {
              let currentDate = new Date();
              currentDate = currentDate.toISOString().split("T")[0];
              arrayHelpers.unshift(
                workOrderJson
                  ? workOrderJson
                  : {
                      work_order: generateQuickGuid("work_order"),
                      request_date: currentDate,
                      order_type: selectedOrderType,
                      occupancy_status: "",
                      completed_date: "",
                      invoice_date: "",
                      invoice_number: "",
                      invoice_status: "",
                      paid: "",
                      amount: 0,
                      check_date: "",
                      check_no: "",
                      selected: false,
                    }
              );
              handleCloseNewOrderDialog();
            }}
          >
            Yes
          </Button>
          <Button
            className={classes.postButtonNo}
            onClick={() => handleCloseNewOrderDialog()}
          >
            No
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default NewOrderDialog;
