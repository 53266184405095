import { Button, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSnackbar } from "notistack";

import Loading from "../Loading";

import FileList from "./FileList";
import fileData from "./fileHelperData.json";
import { DASHBOARD, INVOICE } from "../../types/routeConstants";

const useStyle = makeStyles((theme) => ({
  caseContainer: {
    // width: "928px",

    width: "98%",
    paddingLeft: "2%",
    [theme.breakpoints.down("md")]: {
      width: "90%",
    },
  },
  topBar: {
    height: "50px",
    // backgroundColor: "#f8f8f8",
    padding: "0 2% 0 2%",
    display: "flex",
    alignItems: "center",
    // marginTop: "8vh",
  },
  heading: {
    fontSize: "1em",
    color: "#147CFF",
    //fontWeight: "bold",
  },
  postButton: {
    backgroundColor: theme.palette.primary.main,
    // fontFamily: "poppins",
    //fontWeight: "bold",
    fontSize: ".8em",
    color: "#ffffff",
    textTransform: "inherit",
    // borderRadius: "5px",
    boxShadow: "0px 3px 3px rgba(0,0,0,0.1607843137254902 )",
    textAlign: "center",
    marginRight: "2%",
    border: "1px solid #3C72D9",

    width: "110px",
    // border: "1px solid #3C72D9",
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      opacity: "0.8",
    },
    "&.Mui-disabled": {
      backgroundColor: "#e7e7e7",
      color: "white",
    },
  },
}));

/**

A React functional component for rendering the Search Results component
It provides functionality for displaying list of returned files

*/

function File({}) {
  const classes = useStyle();
  const navigate = useNavigate();
  const { state, pathname } = useLocation();

  const [loading, setLoading] = useState(true);
  const [fileList, setFileList] = useState(null);

  useEffect(() => {
    console.log(state);
    console.log(pathname);
    if (state?.fileList) {
      // show file list
      setFileList(state.fileList);
    } else if (pathname == DASHBOARD.path) {
      setFileList(fileData);
    }
    setLoading(false);
  }, [state]);

  return (
    <>
      {loading ? (
        <Loading pageLoading={true} left={"60%"} />
      ) : (
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",

              paddingTop: "calc(10vh + 50px)",
            }}
          >
            <div className={classes.topBar}>
              <span className={classes.heading}>
                {pathname == DASHBOARD.path
                  ? "File List"
                  : "Returned Search Results "}
              </span>{" "}
            </div>
            <Button
              className={classes.postButton}
              onClick={(e) => {
                // handleTabChange(e, 1);
                navigate(INVOICE.path);
              }}
            >
              Upload New
            </Button>
          </div>
          <br />
          {fileList && fileList.length > 0 && (
            <div className={classes.caseContainer}>
              <FileList fileList={fileList} />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default File;
